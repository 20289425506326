import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "../services/AuthService";

export interface ILoginProps {}

export default function LoginPage(props: ILoginProps) {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  });

  return <></>;
}
