import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import ToDo from "../components/ToDo";
import ProgressBar from "../components/ProgressBar";
import UserHeader from "../components/UserHeader";
import { User } from "../models/Interfaces/UserInterfaces";
import TotalBalanceCard from "../components/TotalBalanceCard";
import ChartCard from "../components/ChartCard";
import PlaylistStatsCard from "../components/PlaylistStatsCard";
import PendingPitchesCard from "../components/PendingPitchesCard";

export default function HomePage({
  unhurdUser,
  updateUser,
}: {
  unhurdUser: User | undefined;
  updateUser: any;
}) {
  document.title = "Dashboard";

  useEffect(() => {
    updateUser();
  }, []);

  useEffect(() => {
    console.log(unhurdUser);
    if (unhurdUser) {
    }
  }, [unhurdUser]);

  return (
    <div className="page-content">
      <UserHeader unhurdUser={unhurdUser} isAddPlaylist={true} />
      <ToDo unhurdUser={unhurdUser} />
      <div className="top-cards">
        <div className="title-card two-cards">
          <TotalBalanceCard unhurdUser={unhurdUser} navButton={true} />
        </div>
        <PendingPitchesCard unhurdUser={unhurdUser} />
      </div>
      <div className="bottom-cards">
        <PlaylistStatsCard unhurdUser={unhurdUser} />
        <div className="title-card">
          <ChartCard unhurdUser={unhurdUser} />
        </div>
      </div>
    </div>
  );
}
