import React, { useContext, useEffect, useState } from "react";
import PaypalLogin from "../PaypalLogin";
import Icon from "@mui/material/Icon";
import UserService from "../../services/UserService";
import { User, useAuth0 } from "@auth0/auth0-react";
import OnboardingContext from "../../Context/OnboardingContext";
import { onboardingComplete } from "../../models/UserModels";
import Loading from "../Loading";
import { Button } from "@mui/material";

export default function WalletSetup({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const { dispatch } = useContext<any>(OnboardingContext);
  const [isPaypalAdded, setIsPaypalAdded] = useState<boolean>(false);
  const [payPalDetails, setPayPalDetails] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useAuth0();

  useEffect(() => {
    setIsLoading(true);

    console.log("wallet useEffect");
    UserService.getUserByID(user?.sub).then((resp: any) => {
      if (resp.paymentDetails?.payPal?.email) {
        setIsPaypalAdded(true);
        setPayPalDetails(resp.paymentDetails.payPal);
      }
      setIsLoading(false);
    });
  }, [unhurdUser]);

  const doWalletLater = () => {
    console.log("do later");
    dispatch({
      type: "PAGE NEXT",
    });
  };

  const completeWallet = async () => {
    const details = await onboardingComplete();
    UserService.updateUserOnboarding(details).then((resp: any) => {
      console.log(resp);
      dispatch({
        type: "PAGE NEXT",
      });
    });
  };

  return (
    <div>
      <h1 className="">Setup your wallet</h1>
      <h3 className="text-faded mt32">
        Setup your wallet using PayPal in order to get paid from unhurd.
      </h3>
      <img className="mt48" src="/images/paypal-logo.svg" alt="email"></img>
      <div></div>
      {!isLoading && !isPaypalAdded ? (
        <div className="mt32">
          {" "}
          <PaypalLogin page="onboarding" />
        </div>
      ) : (
        <div>
          {!isLoading && (
            <div className="text-faded mt32">
              PayPal account{" "}
              <span className="text-white">{payPalDetails?.email}</span> added{" "}
              <span>
                <img src="/images/icons/VerifiedIcon.svg" alt=""></img>
              </span>
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <div className="onboarding-nav-container">
        <div className="onboarding-nav-buttons">
          <div className="do-later-button">
            <Button
              className="mt32"
              onClick={() => {
                doWalletLater();
              }}
            >
              <span className="btn-text">Do this later</span>
            </Button>
          </div>

          <div className="ml-auto">
            <Button
              className="mt32"
              onClick={() => {
                dispatch({
                  type: "PAGE BACK",
                });
              }}
              data-testid="do-it-later-button"
            >
              <Icon className="icon-prefix">chevron_left</Icon>
              <span className="btn-text icon-prefix">Go back</span>
            </Button>

            <Button
              className="mt32 btn-white"
              onClick={() => {
                completeWallet();
              }}
              data-testid="continue-button"
            >
              <span className="btn-text icon-suffix">Continue</span>
              <Icon>chevron_right</Icon>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
