import React, { useContext, useEffect } from "react";
import Loading from "../Loading";
import OnboardingContext from "../../Context/OnboardingContext";

export default function SettingUp() {
  const { dispatch } = useContext<any>(OnboardingContext);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: "PAGE NEXT",
      });
    }, 1000);
  }, []);

  return (
    <div className="mt120">
      <h1 className="">Setting you up...</h1>
      <h3 className="text-faded mt32">
        Hang on a sec whilst we set you up with your new dashboard.
      </h3>
      <div className="centered-loading mt48">
        <Loading />
      </div>
    </div>
  );
}
