import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import PlaylistService from "../services/PlaylistService";

export default function ToDo({ unhurdUser }: { unhurdUser: any }) {
  const [isPlaylists, setIsPlaylists] = useState(true);
  const [isPaypal, setIsPaypal] = useState(true);

  const navigate = useNavigate();

  const getUserPlaylists = () => {
    PlaylistService.getUserPlaylists(unhurdUser.spotifyAccounts[0].id)
      .then((resp: any) => {
        console.log(resp);
        const result = resp?.playlists.filter(
          (item: any) => item.isConnected === true
        );
        console.log(result);
        if (result?.length === 0) setIsPlaylists(false);
      })
      .catch((err: any) => {
        setIsPlaylists(false);
      });
  };

  useEffect(() => {
    if (unhurdUser) {
      console.log(unhurdUser);
      if (unhurdUser.spotifyAccounts?.length > 0) {
        getUserPlaylists();
      }
      if (unhurdUser.paymentDetails?.payPal?.email === undefined) {
        setIsPaypal(false);
      }
    }
  }, [unhurdUser]);

  return (
    <>
      {(!isPlaylists || !isPaypal) && (
        <div className="to-do-card-border" data-testid="todo-card">
          <div className="to-do-card">
            <div className="card-title">
              <div className="card-icon material-icons">
                <div className="mt-6">👋</div>
              </div>
              <div className="titles">
                <p className="text-faded">Hi there,</p>
                <h3 className="text-number">Let's get you started</h3>
              </div>
            </div>
            {!isPlaylists && (
              <div
                className="mt16 d-flex cursor-pointer"
                onClick={() => {
                  navigate("/add-playlist");
                }}
              >
                <Icon className="material-symbols-outlined text-faded">
                  circle
                </Icon>
                <p className="pl16 small">
                  Add your playlists from your Spotify account. Once you have
                  added these playlists, you can receive pitches from different
                  artists.
                </p>
                <Icon className="material-symbols-outlined text-faded ml-auto">
                  chevron_right
                </Icon>
              </div>
            )}
            {!isPaypal && (
              <div
                className="mt8 d-flex cursor-pointer"
                onClick={() => {
                  navigate("/settings", { state: 1 });
                }}
              >
                <Icon className="material-symbols-outlined text-faded">
                  circle
                </Icon>
                <p className="pl16 small">
                  Setup your PayPal wallet in order to get paid from unhurd when
                  accepting playlist pitches from different artists.
                </p>
                <Icon className="material-symbols-outlined text-faded ml-auto">
                  chevron_right
                </Icon>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
