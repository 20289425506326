import React, { useContext, useEffect, useState } from "react";
import SpotifyLogin from "../SpotifyLogin";
import Icon from "@mui/material/Icon";
import SpotifyService from "../../services/SpotifyService";
import { spotifyDetails } from "../../models/UserModels";
import UserService from "../../services/UserService";
import OnboardingContext from "../../Context/OnboardingContext";
import Loading from "../Loading";
import AppContext from "../../Context/AppContext";
import { Button } from "@mui/material";

export default function SpotifyConnect(props: any) {
  const [spotifyInfo, setSpotifyInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(true);
  // const [token, setToken] = useState<any>();

  const token = localStorage.getItem("sp-accessToken");

  const [accountAlreadyConnected, setAccountAlreadyConnected] =
    useState<any>(false);

  const { dispatch } = useContext<any>(OnboardingContext);
  const { dispatchSnackbar } = useContext<any>(AppContext);

  // useEffect(() => {
  //   console.log("check token");
  //   function checkUserData() {
  //     const spToken = localStorage.getItem("sp-accessToken");

  //     if (spToken) {
  //       setToken(spToken);
  //     }
  //   }

  //   window.addEventListener("storage", checkUserData);

  //   return () => {
  //     window.removeEventListener("storage", checkUserData);
  //   };
  // }, []);

  useEffect(() => {
    console.log("token");
    if (token) {
      setIsLoading(true);
      SpotifyService.getCurrentSpotifyUser()
        .then((resp: any) => {
          console.log(resp);
          setSpotifyInfo(resp);
          localStorage.setItem("sp-id", resp.id);
          setIsLoading(false);
        })
        .catch((err: any) => {
          if (localStorage.getItem("sp-accessToken")) {
            localStorage.removeItem("sp-accessToken");
          }
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [token]);

  const updateUser = async () => {
    const details = await spotifyDetails(localStorage.getItem("sp-id"));
    UserService.updateUserOnboarding(details).then((resp: any) => {
      console.log(resp);
      dispatch({
        type: "PAGE NEXT",
      });
      setIsLoading(false);
    });
  };

  const checkIfSpotifyExists = () => {
    setIsLoading(true);
    UserService.checkSpotifyAccountExists(spotifyInfo.id).then((resp: any) => {
      console.log(resp);
      if (resp.curatorConnected) {
        console.log("curator exists");
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message:
              "This Spotify account is already connected to another Unhurd Curator account",
            type: "error",
          },
        });
        setAccountAlreadyConnected(true);
        setIsLoading(false);
      } else {
        updateUser();
      }
    });
  };

  return (
    <div>
      <h1 className="">Connect Your Spotify Account</h1>
      <h3 className="text-faded mt32">
        Connect your Spotify account to select your playlists for pitching.
      </h3>
      {!isLoading && spotifyInfo && (
        <div>
          <img
            className="mt48 spotify-profile-picture"
            src={
              spotifyInfo.images?.length > 0
                ? spotifyInfo.images[spotifyInfo.images?.length - 1]?.url
                : "/images/spotify-logo.png"
            }
            alt="spotify-profile"
          ></img>
          <div></div>
          <div className="text-faded mt32">
            You're connected to{" "}
            <span className="text-white">{spotifyInfo.display_name}'s</span>{" "}
            account
          </div>
        </div>
      )}
      {!isLoading && !spotifyInfo && (
        <div>
          <img
            className="mt48"
            src="/images/spotify-logo.png"
            alt="spotify-logo"
          ></img>
          <div className="mt32"></div>
          <SpotifyLogin />
        </div>
      )}
      {accountAlreadyConnected && (
        <div className="mt32">
          <SpotifyLogin force={true} />
        </div>
      )}
      {isLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <div className="onboarding-nav-container">
        <div className="onboarding-nav-buttons">
          <div className="ml-auto">
            <Button
              className="mt32 btn-white"
              disabled={!spotifyInfo || isLoading}
              onClick={() => {
                checkIfSpotifyExists();
              }}
              data-testid="submit-spotify-data"
            >
              <span className="btn-text icon-suffix">Continue</span>
              <Icon>chevron_right</Icon>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
