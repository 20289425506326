import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { TrackPlayingIndex } from "../models/Interfaces/PlaylistInterface";
import { durationFormatter } from "../formatters/DurationFormatter";
import FeedbackModal from "./FeedbackModal";
import {
  pitchTrack,
  pitchTrackArtist,
} from "../models/Interfaces/TrackInterfaces";
import { dateTimeFormatter } from "../formatters/DateTimeFormatter";

export interface IPitchesTableProps {
  tracks: pitchTrack[];
  playing: (
    track: pitchTrack,
    index: string | undefined,
    pause: boolean | undefined,
    playlistId: string | undefined
  ) => void;
  playlistId?: string;
  playingIndex?: TrackPlayingIndex;
  selectedTracks: pitchTrack[];
  setSelectedTracks: (tracks: pitchTrack[]) => void;
  updateTracks: (update: boolean) => void;
  pageNumber?: number;
  bottomTrackIntersecting?: (intersecting: any) => void;
}

export default function PitchesTable({
  tracks,
  playing,
  playlistId,
  playingIndex,
  selectedTracks,
  setSelectedTracks,
  updateTracks,
  pageNumber,
  bottomTrackIntersecting,
}: IPitchesTableProps) {
  const columns: string[] = [
    "checkbox",
    "#",
    "Title",
    "Artist",
    "Album",
    "Duration",
    "Date/Time",
    "",
  ];
  const [isPlayingIndex, setIsPlayingIndex] = useState<TrackPlayingIndex>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedTrack, setSelectedTrack] = useState<pitchTrack[]>([]);
  const [accept, setAccept] = useState<boolean>();

  const ref = useRef<any>(null);

  useEffect(() => {
    console.log(playingIndex);
    setIsPlayingIndex(playingIndex);
  }, [playingIndex]);

  const isChecked = (event: pitchTrack) => {
    const result = selectedTracks.filter((item: pitchTrack) => item === event);
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const playTrackOnSpotify = (
    track: pitchTrack,
    index: string | undefined,
    pause?: boolean,
    playlistId?: string
  ) => {
    console.log(track, index, pause, playlistId);
    playing(track, index, pause, playlistId);
  };

  const handleCheckboxChange = (event: pitchTrack) => {
    const result = selectedTracks.filter((item: pitchTrack) => item === event);
    if (result.length > 0) {
      const checked = selectedTracks.filter(
        (item: pitchTrack) => item !== event
      );
      setSelectedTracks(checked);
    } else {
      setSelectedTracks([...selectedTracks, event]);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const playFromModal = (track: pitchTrack, pause: boolean | undefined) => {
    playTrackOnSpotify(track, undefined, pause);
  };

  useEffect(() => {
    console.log(tracks);
    if (tracks && tracks.length > 0) {
      const observer = new IntersectionObserver((entries: any) => {
        console.log(entries[0].isIntersecting);
        if (entries[0].isIntersecting) {
          if (bottomTrackIntersecting) {
            bottomTrackIntersecting(entries);
          }
        }
      }, {});
      observer.observe(ref.current);
    }
  }, [tracks]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <colgroup>
            <col width="1%" />
            <col width="1%" />
            <col width="20%" />
            <col width="20%" />
            <col width="15%" />
            <col width="5%" />
            <col width="10%" />
            <col width="20%" />
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>
                  {column === "checkbox" ? (
                    <div className="pl8">
                      <div
                        className="checkbox-icon"
                        onClick={() => {
                          if (selectedTracks.length > 0) {
                            setSelectedTracks([]);
                          } else {
                            setSelectedTracks(tracks);
                          }
                        }}
                      >
                        {selectedTracks.length === tracks.length && (
                          <div className="checked"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    column
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tracks?.map((row: pitchTrack, index: number) => (
              <TableRow
                className={
                  row.track.spotifyId === isPlayingIndex?.trackIndex &&
                  playlistId === isPlayingIndex.playlistId
                    ? "item-playing"
                    : ""
                }
                ref={index === tracks.length - 1 ? ref : null}
                data-testid={`pitch-item-row-${index}`}
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "--animation-number": index % 20,
                }}
              >
                <TableCell>
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        id="playlist-checkbox"
                        name="playlist-checkbox"
                        icon={<div className="checkbox-icon"></div>}
                        checkedIcon={
                          <div className="checkbox-icon">
                            <div className="checked"></div>
                          </div>
                        }
                        checked={isChecked(row)}
                        onChange={() => {
                          handleCheckboxChange(row);
                        }}
                        value={row.track.spotifyId}
                      />
                    }
                  />
                </TableCell>
                <TableCell>
                  {pageNumber ? pageNumber * 10 + index + 1 : index + 1}
                </TableCell>
                <TableCell>
                  <div
                    className="table-item-with-image cursor-pointer"
                    onClick={() => {
                      window.open(row.track.url);
                    }}
                  >
                    <img
                      src={
                        row.track?.image
                          ? row.track?.image
                          : "images/logos/no-image-available.svg"
                      }
                      alt=""
                    />
                    <span>{row.track?.name}</span>
                  </div>
                </TableCell>
                <TableCell width="20%">
                  {/* {row.artist.name} */}
                  {row.track?.artists?.map(
                    (artist: pitchTrackArtist, index: number) =>
                      index === row.track?.artists.length - 1
                        ? artist.name
                        : artist.name + ", "
                  ) || "N/A"}
                </TableCell>
                <TableCell>{row.track?.albumTitle || "N/A"}</TableCell>
                <TableCell>
                  {durationFormatter(row.track?.duration, "msToMM:SS")}
                </TableCell>
                <TableCell>{dateTimeFormatter(row.createdAt)}</TableCell>
                <TableCell>
                  <div className="table-end-call-with-button">
                    {/* {row.track.previewUrl ? ( */}
                    <Button className="icon-btn ml-auto">
                      {isPlayingIndex?.trackIndex === row.track.spotifyId &&
                      isPlayingIndex?.playlistId === playlistId ? (
                        <Icon
                          onClick={() => {
                            playTrackOnSpotify(row, undefined, true);
                          }}
                          className="material-symbols-outlined"
                        >
                          stop_circle
                        </Icon>
                      ) : (
                        <Icon
                          onClick={() => {
                            playTrackOnSpotify(
                              row,
                              row.track.spotifyId,
                              false,
                              playlistId
                            );
                          }}
                          className="material-symbols-outlined"
                        >
                          play_circle
                        </Icon>
                      )}
                    </Button>
                    {row.track.url && row.track.url !== "" && (
                      <Tooltip title="View on Spotify" arrow placement="top">
                        <Button
                          className="icon-btn ml0"
                          onClick={() => {
                            window.open(row.track.url);
                          }}
                        >
                          <img
                            src="/images/spotify-logo.png"
                            alt="spot-logo"
                          ></img>
                        </Button>
                      </Tooltip>
                    )}

                    <Button
                      className="border-btn"
                      onClick={() => {
                        setSelectedTrack([row]);
                        setAccept(false);
                        setModalOpen(true);
                      }}
                    >
                      <span className="btn-text icon-suffix">Reject</span>
                      <Icon>close</Icon>
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedTrack([row]);
                        setAccept(true);
                        setModalOpen(true);
                      }}
                      className="btn-white"
                    >
                      <span className="btn-text icon-suffix">Accept</span>
                      <Icon>done</Icon>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <React.Fragment>
          <FeedbackModal
            tracks={selectedTrack}
            accept={accept}
            closeModalOutput={setModalOpen}
            playTrack={playFromModal}
            updateTracks={() => {
              updateTracks(true);
            }}
          ></FeedbackModal>
        </React.Fragment>
      </Modal>
    </>
  );
}
