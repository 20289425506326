import { Button } from "@mui/material";
import React from "react";

export interface IPaypalLoginProps {
  page?: string;
  type?: string;
}

export default function PaypalLogin({ page, type }: IPaypalLoginProps) {
  const response_type = "code";
  const scope = encodeURIComponent(
    "openid profile email address https://uri.paypal.com/services/paypalattributes"
  );
  let url = encodeURIComponent(`${window.location.origin}/paypal-callback`);
  console.log(window.location.origin);

  if (window.location.origin === "http://localhost:3000") {
    url = encodeURIComponent(`http://127.0.0.1:3000/paypal-callback`);
  }

  const client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const state = page || "no-page";

  const openPaypalLogin = () => {
    if (process.env.REACT_APP_NODE_ENV === "dev") {
      window.location.href = `https://www.sandbox.paypal.com/connect/?client_id=${client_id}&redirect_uri=${url}&response_type=${response_type}&scope=${scope}&state=${state}`;
    } else {
      window.location.href = `https://www.paypal.com/connect/?client_id=${client_id}&redirect_uri=${url}&response_type=${response_type}&scope=${scope}&state=${state}`;
    }
  };

  return (
    <>
      {type === "change" ? (
        <Button
          className="text-blue-gradient fw-normal min-w0"
          onClick={() => {
            openPaypalLogin();
          }}
          data-testid="paypal-button-change"
        >
          Change
        </Button>
      ) : (
        <Button
          className="btn-white"
          onClick={() => {
            openPaypalLogin();
          }}
          data-testid="paypal-button"
        >
          Connect your PayPal account
        </Button>
      )}
    </>
  );
}
