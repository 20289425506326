import { PayPalUpdateObject } from "./Interfaces/UserInterfaces";

const userID = async () => {
  const token = await localStorage.getItem("userID");
  console.log(token);
  return token;
};

const unhurdId = async () => {
  const token = await localStorage.getItem("unhurdID");
  console.log(token);
  return token;
};

export const userDetails = async (
  firstName: string,
  lastName: string,
  email: string,
  businessName: string
) => {
  return {
    userId: await userID(),
    id: await unhurdId(),
    firstName: firstName,
    lastName: lastName,
    businessName: businessName,
    email: email,
    onboardingStage: 1,
  };
};

export const spotifyDetails = async (spotifyId: string | null) => {
  return {
    userId: await userID(),
    id: await unhurdId(),
    spotifyId: spotifyId,
    onboardingStage: 2,
  };
};

export const playlistsChosen = async () => {
  return {
    userId: await userID(),
    id: await unhurdId(),
    onboardingStage: 3,
  };
};

export const walletSetup = async (paymentDetails: any) => {
  console.log(paymentDetails);
  return {
    userId: await userID(),
    id: await unhurdId(),
    onboardingStage: 4,
    payPalDetails: paymentDetails,
  };
};

export const onboardingComplete = async () => {
  return {
    userId: await userID(),
    id: await unhurdId(),
    onboardingStage: 5,
  };
};
