import React from "react";
import Icon from "@mui/material/Icon";
import { onboardingComplete } from "../../models/UserModels";
import UserService from "../../services/UserService";
import { Button } from "@mui/material";

export default function ReadyToGo({ readyToGo }: { readyToGo?: any }) {
  const handleNextClick = async (event: any) => {
    const details = await onboardingComplete();
    UserService.updateUserOnboarding(details).then((resp: any) => {
      console.log(resp);
      window.location.replace(window.location.origin);
    });
  };
  return (
    <div className="mt120">
      <h1 className="">🎉 Great! Let’s get you started.</h1>
      <h3 className="text-faded mt32">
        You’re all setup and ready to go with your unhurd dashboard!
      </h3>
      <div className="pos-rel">
        <img src="/images/dashboard-preview.png" alt="email"></img>
        <Button
          className="btn-white view-dashboard-button"
          onClick={handleNextClick}
          data-testid="end-onboarding"
        >
          <span className="btn-text icon-suffix">View my dashboard</span>
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </div>
  );
}
