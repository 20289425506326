import React, { useEffect, useReducer, useState } from "react";
import "./App.scss";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/Home";
import PlaylistsPage from "./pages/Playlists";
import NavBar from "./components/NavBar";
import SpotifyCallbackPage from "./pages/SpotifyCallbackPage";
import SettingsPage from "./pages/SettingsPage";
import OnboardingPage from "./pages/Onboarding";
import UserService from "./services/UserService";
import PlaylistPage from "./pages/Playlist";
import AddPlaylistPage from "./pages/AddPlaylistPage";
import PaypalCallbackPage from "./pages/PaypalCallbackPage";
import snackbarReducer from "./reducers/snackbarReducer";
import AppContext from "./Context/AppContext";
import SnackbarComponent from "./components/Snackbar";
import { User } from "./models/Interfaces/UserInterfaces";
import TotalBalancePage from "./pages/TotalBalancePage";
import AllPlaylistPitchesPage from "./pages/AllPlaylistPitchesPage";
import LoginPage from "./components/LoginPage";
import ScreenSizePlaceholder from "./components/ScreenSizePlaceholder";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute";
import Loading from "./components/Loading";

function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [unhurdUser, setUnhurdUser] = useState<User>();
  const navigate = useNavigate();
  const location = useLocation();
  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  const initialState = {
    snackbar: {
      message: "",
      type: "",
      open: false,
    },
  };
  useEffect(() => {
    const currentDomain = window.location.href;
    if (
      currentDomain.includes("paypal-callback") &&
      currentDomain.includes("state=")
    ) {
      const newDomain = currentDomain.replace("state=", "other=");
      window.location.href = newDomain;
    }
  }, []);

  const handleResize = () => {
    window.innerWidth <= 900 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [window.innerWidth]);

  const [snackbar, dispatchSnackbar] = useReducer(
    snackbarReducer,
    initialState
  );

  const getUser = (userId: string | undefined) => {
    UserService.getUserByID(userId || user?.sub)
      .then((resp: User) => {
        if (resp) {
          console.log(resp);
          localStorage.setItem("userID", resp.userId);
          localStorage.setItem("unhurdID", resp.id);
          setUnhurdUser(resp);
          if (
            resp.accountConfiguration === null ||
            resp.accountConfiguration?.onboardingStage < 5
          ) {
            navigate("/onboarding");
          }
          setPageIsLoading(false);
        } else {
          navigate("/onboarding");
          setPageIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 404) {
          console.log("NO USER IN OUR DATABASE");
          navigate("/onboarding");
          setPageIsLoading(false);
        }
        if (err.response?.status === 500) {
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message:
                "Oops, something went wrong, try refreshing the page or coming back later",
              type: "error",
            },
          });
        }
      });
  };

  const navigateHome = () => {
    navigate("/");
    setPageIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading && user) {
      getAccessTokenSilently().then((resp) => {
        localStorage.setItem("auth_token", resp);
        if (!user?.email_verified) {
          navigate("/onboarding");
        }
        if (!unhurdUser) {
          console.log("getuser");
          console.log(location);
          if (
            location.pathname !== "/spotify-callback" &&
            location.pathname !== "/paypal-callback"
          ) {
            getUser(user?.sub);
          }
          if (location.search.includes("error_uri")) {
            console.log("contains error_uri");
            navigate("/onboarding");
            setPageIsLoading(false);
          }
        }
      });
    }
    if (
      !isLoading &&
      !isAuthenticated &&
      !user &&
      location.pathname !== "/spotify-callback" &&
      location.pathname !== "/paypal-callback"
    ) {
      navigateHome();
    }
  }, [user, isAuthenticated, isLoading, getAccessTokenSilently]);

  return (
    <>
      {!breakPointHit && (
        <AppContext.Provider value={{ snackbar, dispatchSnackbar }}>
          <Routes>
            {unhurdUser &&
              unhurdUser.accountConfiguration.onboardingStage === 5 && (
                <Route path="/" element={<Navigate to="/dashboard" />} />
              )}
            {unhurdUser &&
              unhurdUser.accountConfiguration.onboardingStage < 5 && (
                <Route path="/" element={<Navigate to="/onboarding" />} />
              )}
            {!isAuthenticated && !isLoading && (
              <Route path="/" element={<LoginPage />} />
            )}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  element={
                    <div>
                      <div className="App">
                        <NavBar />
                        <HomePage
                          unhurdUser={unhurdUser}
                          updateUser={getUser}
                        />
                      </div>
                      <Footer />
                    </div>
                  }
                />
              }
            />

            <Route
              path="/playlists"
              element={
                <ProtectedRoute
                  element={
                    <div className="App">
                      <NavBar />
                      <PlaylistsPage unhurdUser={unhurdUser} />
                    </div>
                  }
                />
              }
            />

            <Route
              path="/playlists/:slug"
              element={
                <ProtectedRoute
                  element={
                    <div className="App">
                      <NavBar />
                      <PlaylistPage unhurdUser={unhurdUser} />
                    </div>
                  }
                />
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute
                  element={
                    <div className="App">
                      <NavBar />
                      <SettingsPage unhurdUser={unhurdUser} />
                    </div>
                  }
                />
              }
            />
            <Route
              path="/dashboard/totalbalance"
              element={
                <ProtectedRoute
                  element={
                    <div className="App">
                      <NavBar />
                      <TotalBalancePage unhurdUser={unhurdUser} />
                    </div>
                  }
                />
              }
            />
            <Route
              path="/dashboard/pendingpitches"
              element={
                <ProtectedRoute
                  element={
                    <div className="App">
                      <NavBar />
                      <AllPlaylistPitchesPage unhurdUser={unhurdUser} />
                    </div>
                  }
                />
              }
            />
            <Route
              path="/add-playlist"
              element={
                <ProtectedRoute
                  element={<AddPlaylistPage unhurdUser={unhurdUser} />}
                />
              }
            />
            <Route
              path="/onboarding"
              element={
                <ProtectedRoute
                  element={<OnboardingPage unhurdUser={unhurdUser} />}
                />
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/spotify-callback"
              element={
                <ProtectedRoute
                  element={<SpotifyCallbackPage unhurdUser={unhurdUser} />}
                />
              }
            />
            <Route
              path="/paypal-callback"
              element={<PaypalCallbackPage updateUser={getUser} />}
            />
          </Routes>
          <SnackbarComponent />
        </AppContext.Provider>
      )}
      {pageIsLoading && !breakPointHit && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {breakPointHit && <ScreenSizePlaceholder />}
    </>
  );
}

export default App;
