import React, { useContext, useEffect, useState } from "react";
import Loading from "../Loading";
import Icon from "@mui/material/Icon";
import PlaylistService from "../../services/PlaylistService";
import OnboardingContext from "../../Context/OnboardingContext";
import { playlistsChosen } from "../../models/UserModels";
import UserService from "../../services/UserService";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import AppContext from "../../Context/AppContext";

export default function ChoosePlaylists() {
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [verifiedPlaylists, setVerifiedPlaylists] = useState([]);
  const [unverifiedPlaylists, setUnverifiedPlaylists] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { dispatch } = useContext<any>(OnboardingContext);
  const { dispatchSnackbar } = useContext<any>(AppContext);

  const [playlistsLoading, setPlaylistsLoading] = useState(true);

  const spotifyID = localStorage.getItem("sp-id");

  useEffect(() => {
    if (spotifyID) {
      PlaylistService.getUserPlaylists(spotifyID)
        .then((resp: any) => {
          console.log(resp);
          const verified: any = [];
          const unverified: any = [];
          resp.playlists.forEach((item: any) => {
            if (item.isConnected) {
              verified.push(item);
            } else {
              unverified.push(item);
            }
          });
          console.log(verified);
          console.log(unverified);
          setVerifiedPlaylists(verified);
          setUnverifiedPlaylists(unverified);
          setPlaylistsLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message:
                "We can't find your playlists on Spotify - check you have made a playlist and refresh this page",
              type: "error",
            },
          });
          setPlaylistsLoading(false);
        });
    }
  }, []);

  const handleCheckboxChange = (event: any) => {
    const result = playlists.filter((item: any) => item === event);
    if (result.length > 0) {
      const checked = playlists.filter((item: any) => item !== event);
      setPlaylists(checked);
    } else {
      setPlaylists([...playlists, event]);
    }
  };

  const submitPlaylists = async () => {
    console.log(playlists);
    setIsSubmitting(true);
    if (playlists.length > 0) {
      const data = {
        playlistsToConnect: playlists,
      };
      await PlaylistService.addPlaylists(data).then((resp) => {
        console.log(resp);
      });
    }
    const details = await playlistsChosen();
    UserService.updateUserOnboarding(details)
      .then((resp: any) => {
        console.log(resp);
        dispatch({
          type: "PAGE NEXT",
        });
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: "Oops, something went wrong, try again",
            type: "error",
          },
        });
        setIsSubmitting(false);
      });
  };

  const doLaterButton = async () => {
    const details = await playlistsChosen();
    UserService.updateUserOnboarding(details).then((resp: any) => {
      console.log(resp);
      dispatch({
        type: "PAGE NEXT",
      });
    });
  };

  const isChecked = (event: any) => {
    const result = playlists.filter((item: any) => item === event);
    return result.length > 0;
  };

  return (
    <div>
      <h1 className="">Choose your playlists</h1>
      <h3 className="text-faded mt32">
        Choose your playlists from your Spotify account that you wish to connect
        to unhurd. Once you have connected these playlists, you can receive
        pitches from different artists.
      </h3>
      <h3 className="mt48 text-left">Verified playlists</h3>
      {!playlistsLoading && verifiedPlaylists.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-playlists-to-connect">
            You don't have any connected playlists yet.
          </h4>
        </div>
      )}
      {!playlistsLoading ? (
        <div className="playlists-container mt32">
          {verifiedPlaylists?.map((item: any) => (
            <div key={item.spotifyId} className="playlist-item is-connected">
              <div className="playlist-item-checkbox-container">
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      id="playlist-checkbox"
                      name="playlist-checkbox"
                      icon={<div className="checkbox-icon"></div>}
                      checked={item.isConnected}
                      checkedIcon={
                        <div className="checkbox-icon">
                          <div className="checked"></div>
                        </div>
                      }
                      onChange={() => {}}
                      value={item.id}
                    />
                  }
                />
              </div>
              <img
                src={
                  item.image?.url
                    ? item.image?.url
                    : "/images/logos/no-image-available.svg"
                }
                alt="playlist-img"
              />
              <div className="playlist-titles">
                <div
                  className={`title  ${
                    item.isConnected ? "text-blue-gradient" : ""
                  }`}
                >
                  {item.name ? item.name : "No title"}
                </div>
                <div className="followers text-faded">
                  Tracks: {item.totalTracks}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <h3 className="mt48 text-left">Unselected playlists</h3>
      {!playlistsLoading && unverifiedPlaylists.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-playlists-to-connect">
            You don't have any playlists to connect
          </h4>
        </div>
      )}
      {!playlistsLoading ? (
        <div className="playlists-container mt32 mb4rem">
          {unverifiedPlaylists?.map((item: any) => (
            <div
              key={item.spotifyId}
              className="playlist-item cursor-pointer"
              onClick={() => {
                handleCheckboxChange(item);
              }}
            >
              <div className="playlist-item-checkbox-container">
                <Checkbox
                  id="playlist-checkbox"
                  name="playlist-checkbox"
                  icon={<div className="checkbox-icon"></div>}
                  checked={isChecked(item)}
                  checkedIcon={
                    <div className="checkbox-icon">
                      <div className="checked"></div>
                    </div>
                  }
                  onChange={() => {
                    handleCheckboxChange(item);
                  }}
                  value={item.id}
                />
              </div>
              <img
                src={
                  item.image?.url
                    ? item.image?.url
                    : "/images/logos/no-image-available.svg"
                }
                alt="playlist-img"
              />
              <div className="playlist-titles">
                <div
                  className={`title  ${
                    item.isConnected ? "text-blue-gradient" : ""
                  }`}
                >
                  {item.name ? item.name : "No title"}
                </div>
                <div className="followers text-faded">
                  Tracks: {item.totalTracks}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <div className="do-later-button">
        <Button
          disabled={isSubmitting}
          className="mt32"
          onClick={() => {
            doLaterButton();
          }}
          data-testid="do-it-later-button"
        >
          <span className="btn-text">Do this later</span>
        </Button>
      </div>

      <div className="continue-button">
        <Button
          disabled={isSubmitting}
          className="mt32 btn-white"
          onClick={() => {
            submitPlaylists();
          }}
          data-testid="continue-button"
        >
          <span className="btn-text icon-suffix">Continue</span>
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </div>
  );
}
