import DataService from "./DataService";

const SpotifyService = {
  getCurrentSpotifyUser: async function () {
    const url = `/me`;
    return new Promise((res, reject) => {
      DataService.getSpotify(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  playTrackOnSpotify: async function (
    data: object,
    deviceId: string,
    pause?: boolean
  ) {
    console.log(data);
    let url = pause ? `/me/player/pause` : `/me/player/play`;
    url = deviceId === "" ? url : url + `?device_id=${deviceId}`;
    return new Promise((res, reject) => {
      DataService.putSpotify(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.error) {
            reject(err.response.data.error);
          } else {
            reject(err);
          }
        });
    });
  },
  getPlaylist: async function (playlistId: string) {
    const url = `/playlists/${playlistId}`;
    return new Promise((res, reject) => {
      DataService.getSpotify(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getPlaylistTracks: async function (playlistId: string, offset: number) {
    const url = `/playlists/${playlistId}/tracks?limit=50&offset=${offset}`;
    return new Promise((res, reject) => {
      DataService.getSpotify(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  addItemsToPlaylists: async function (playlistId: string, data: object) {
    const url = `/playlists/${playlistId}/tracks`;
    return new Promise((res, reject) => {
      DataService.postSpotify(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default SpotifyService;
