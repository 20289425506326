import React, { useContext, useEffect, useReducer, useState } from "react";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import PlaylistService from "../services/PlaylistService";
import Loading from "../components/Loading";
import { User } from "../models/Interfaces/UserInterfaces";
import AppContext from "../Context/AppContext";
import { Button, Checkbox, FormControlLabel } from "@mui/material";

export default function AddPlaylistPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  document.title = "Add playlists";
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [playlistsLoading, setPlaylistsLoading] = useState<boolean>(true);
  const [selectedPlaylists, setSelectedPlaylists] = useState<any[]>([]);

  const { dispatchSnackbar } = useContext<any>(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (unhurdUser) {
      PlaylistService.getUserPlaylists(unhurdUser.spotifyAccounts[0].id)
        .then((resp: any) => {
          const result = resp.playlists.filter(
            (item: any) => !item.isConnected
          );
          setPlaylists(result);
          setPlaylistsLoading(false);
        })
        .catch((err: any) => {
          setPlaylists([]);
          setPlaylistsLoading(false);
        });
    }
  }, [unhurdUser]);

  const handleCheckboxChange = (event: any) => {
    const result = selectedPlaylists.filter((item: any) => item === event);
    if (result.length > 0) {
      const checked = selectedPlaylists.filter((item: any) => item !== event);
      setSelectedPlaylists(checked);
    } else {
      setSelectedPlaylists([...selectedPlaylists, event]);
    }
  };

  const submitPlaylists = () => {
    setPlaylistsLoading(true);
    console.log(selectedPlaylists);
    if (selectedPlaylists.length > 0) {
      const data = {
        playlistsToConnect: selectedPlaylists,
      };
      PlaylistService.addPlaylists(data)
        .then((resp) => {
          console.log(resp);
          navigate(-1);
          setPlaylistsLoading(false);
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message: "Your playlists have been added successfully",
              type: "success",
            },
          });
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response.data.statusCode === 400) {
            dispatchSnackbar({
              type: "OPEN_SNACKBAR",
              payload: {
                message: err.response.data.errorMessage,
                type: "error",
              },
            });
            setPlaylistsLoading(false);
          }
        });
    }
  };

  const isChecked = (event: any) => {
    const result = selectedPlaylists.filter((item: any) => item === event);
    return result.length > 0;
  };

  return (
    <div className="onboarding-page">
      <h1>Add a new playlist</h1>
      <h3 className="text-faded">
        Choose your playlists from your Spotify account that you wish to connect
        to unhurd. Once you have connected these playlists, you can receive
        pitches from different artists.
      </h3>
      <Button
        className="icon-btn add-playlist-close-btn"
        onClick={() => {
          navigate(-1);
        }}
        data-testid="close-add-new-playlists-button"
      >
        <Icon>close</Icon>
      </Button>
      {!playlistsLoading ? (
        <div className="playlists-container mt32">
          {playlists?.map((item: any) => (
            <div
              key={item.spotifyId}
              className="playlist-item cursor-pointer"
              onClick={() => {
                handleCheckboxChange(item);
              }}
            >
              <div className="playlist-item-checkbox-container">
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      id="playlist-checkbox"
                      name="playlist-checkbox"
                      checked={isChecked(item)}
                      icon={<div className="checkbox-icon"></div>}
                      checkedIcon={
                        <div className="checkbox-icon">
                          <div className="checked"></div>
                        </div>
                      }
                      onChange={() => {
                        handleCheckboxChange(item);
                      }}
                      value={item.id}
                    />
                  }
                />
              </div>
              <img
                src={
                  item.image?.url
                    ? item.image.url
                    : "/images/logos/no-image-available.svg"
                }
                alt="playlist-img"
              />
              <div className="playlist-titles">
                <div
                  className={`title ${
                    item.isConnected ? "text-blue-gradient" : ""
                  }`}
                >
                  {item.name ? item.name : "No title"}
                </div>
                <div className="followers text-faded">
                  Tracks: {item.totalTracks}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {!playlistsLoading && playlists.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-playlists-to-connect">
            You don't have any playlists to connect
          </h4>
        </div>
      )}
      <Button
        disabled={selectedPlaylists.length === 0}
        className="btn-white continue-btn"
        onClick={submitPlaylists}
        data-testid="add-playlists-continue-button"
      >
        <span className="btn-text icon-suffix">Continue</span>
        <Icon>chevron_right</Icon>
      </Button>
    </div>
  );
}
