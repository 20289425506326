import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Icon, Modal } from "@mui/material";
import { User } from "../models/Interfaces/UserInterfaces";
import { useNavigate } from "react-router-dom";
import PlaylistAccordion from "../components/PlaylistAccordion";
import PlaylistService from "../services/PlaylistService";
import SpotifyWebPlayer from "../components/SpotifyWebPlayer";
import SpotifyService from "../services/SpotifyService";
import {
  SpotifyTracks,
  TrackPlayingIndex,
  UnhurdPlaylist,
} from "../models/Interfaces/PlaylistInterface";
import FeedbackModal from "../components/FeedbackModal";
import Loading from "../components/Loading";
import { pitchTrack } from "../models/Interfaces/TrackInterfaces";
import moment from "moment";
import SpotifyLogin from "../components/SpotifyLogin";
import AppContext from "../Context/AppContext";

export default function AllPlaylistPitchesPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  document.title = "Playlist Pitches";
  const navigate = useNavigate();
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [spDeviceId, setSpDeviceId] = useState<string>();
  const [currentTrack, setCurrentTrack] = useState<any>();
  const [currentState, setCurrentState] = useState<any>();
  const [isPlayingIndex, setIsPlayingIndex] = useState<TrackPlayingIndex>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isLoadingPlaylists, setIsLoadingPlaylists] = useState<boolean>(true);
  const [playlistSelections, setPlaylistSelections] = useState<any>([]);
  const [trackSelections, setTrackSelections] = useState<any>([]);
  const [accept, setAccept] = useState<boolean>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [numberOfPendingPitches, setNumberOfPendingPitches] =
    useState<number>(0);
  const { dispatchSnackbar } = useContext<any>(AppContext);

  const ref = useRef<any>(null);

  const spAccessToken = localStorage.getItem("sp-accessToken");
  const spExpire = localStorage.getItem("sp-expireTime");

  const playTrackOnSpotify = (
    track: any,
    index: any,
    pause?: boolean,
    playlistId?: string
  ) => {
    console.log(index);
    const data = {
      uris: [`spotify:track:${track?.track?.spotifyId}`],
    };
    SpotifyService.playTrackOnSpotify(data, spDeviceId ? spDeviceId : "", pause)
      .then((resp: any) => {
        setIsPlayingIndex({ playlistId: playlistId, trackIndex: index });
      })
      .catch((err) => {
        console.log(err);
        if (err?.status === 401) {
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message:
                "You need to be logged in to Spotify to use this feature",
              type: "",
            },
          });
        }
        if (
          (err?.reason === "PREMIUM_REQUIRED" || err?.status === 403) &&
          !pause
        ) {
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message: "You need a Spotify Premium account to use this feature",
              type: "",
            },
          });
        }
        if (err.response?.status === 403) {
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message:
                "It looks like this spotify account isn't registered with us - please send us a support message to resolve this issue.",
              type: "",
            },
          });
        }
      });
  };

  const isLoggedIn = () => {
    let isNotExpired = false;
    if (spExpire) {
      isNotExpired = moment(spExpire).isAfter(moment());
    }
    if (spAccessToken && isNotExpired) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (currentState === "TRACK_ENDED") {
      setIsPlayingIndex({ playlistId: undefined, trackIndex: undefined });
      setCurrentTrack(undefined);
    }
  }, [currentState]);

  useEffect(() => {
    const tracks: any[] = [];
    playlistSelections.forEach((item: any) => {
      item.tracks.forEach((track: any) => {
        tracks.push(track);
      });
    });
    setTrackSelections(tracks);
  }, [playlistSelections]);

  const getPlaylists = (reset?: boolean) => {
    PlaylistService.getConnectedUserPlaylists(
      unhurdUser?.spotifyAccounts[0].id,
      20,
      "accepted",
      "pending-pitches",
      "asc",
      reset ? 1 : pageNumber
    ).then((resp: any) => {
      console.log(resp);
      setTotalItems(resp.totalItems);
      if (reset) {
        setPageNumber(1);
        setPlaylists(resp?.items);
      } else {
        setPageNumber(pageNumber + 1);
        setPlaylists(playlists.concat(resp?.items));
      }
      let numberOfPitches = playlists.length;
      resp.items.forEach((item: UnhurdPlaylist) => {
        numberOfPitches = numberOfPitches + item.stats.pendingPitches;
      });
      setNumberOfPendingPitches(numberOfPitches);
      setIsLoadingPlaylists(false);
      setIsLoadingMore(false);
    });
  };

  useEffect(() => {
    if (unhurdUser) {
      getPlaylists();
    }
  }, [unhurdUser]);

  useEffect(() => {
    if (playlists.length > 0) {
      const observer = new IntersectionObserver((entries: any) => {
        console.log(entries[0]);
        console.log(pageNumber);
        if (
          entries[0].isIntersecting &&
          !isLoadingMore &&
          playlists.length < totalItems
        ) {
          console.log(entries[0].isIntersecting);
          setIsLoadingMore(true);
          getPlaylists();
        }
      }, {});
      observer.observe(ref.current);
    }
  }, [playlists]);

  const handleModalClose = (event: any) => {
    setModalOpen(false);
  };

  const updateSelections = (tracks: any[], playlist: any) => {
    const playlistSelectionItem = {
      tracks: tracks,
      playlistID: playlist.id,
    };
    const index = playlistSelections.findIndex(
      (item: any) => item.playlistID === playlist.id
    );
    if (index === -1) {
      setPlaylistSelections([...playlistSelections, playlistSelectionItem]);
    } else {
      const newPlaylistSelections = playlistSelections.filter(
        (item: any) => item.playlistID !== playlist.id
      );
      newPlaylistSelections.push(playlistSelectionItem);
      setPlaylistSelections(newPlaylistSelections);
    }
  };

  const playFromModal = (track: pitchTrack, pause: boolean | undefined) => {
    playTrackOnSpotify(track, undefined, pause);
  };

  return (
    <div className="page-content">
      <SpotifyWebPlayer
        deviceId={setSpDeviceId}
        currentTrack={setCurrentTrack}
        currentState={setCurrentState}
      />
      <div className="d-flex">
        <div>
          <p
            className="text-faded cursor-pointer d-flex"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon>chevron_left</Icon>
            <span className="pt2">Dashboard</span>
          </p>
          <h2 data-testid="pending-pitches-title">All pending pitches</h2>
          <p className="mt16 mb16 text-faded">
            You can review all of your pending pitches below and decide whether
            you want to either reject them or accept them onto your playlist.
          </p>
        </div>
        {!isLoggedIn() && (
          <div className="ml-auto">
            <SpotifyLogin login />
          </div>
        )}
      </div>

      <h3 className="mt-auto mb16" data-testid="playlist-pending-pitches">
        Pending pitches: {numberOfPendingPitches}
      </h3>
      {!isLoadingPlaylists &&
        playlists?.map((playlist: any, index: number) => (
          <div
            data-testid={`playlist-item-${index}`}
            key={playlist.id}
            ref={index === playlists.length - 1 ? ref : null}
          >
            <PlaylistAccordion
              playlist={playlist}
              playingIndex={isPlayingIndex}
              expanded={index === 0}
              playing={(
                track: any,
                index: any,
                pause: any,
                playlistId: any
              ) => {
                playTrackOnSpotify(track, index, pause, playlistId);
              }}
              setMultipleSelectedTracks={updateSelections}
            />
          </div>
        ))}
      {isLoadingPlaylists && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {isLoadingMore && <div className="text-center">Loading more...</div>}
      {trackSelections.length > 0 && (
        <div className="playlist-select-footer d-flex">
          <div className="mt-auto mb-auto">
            {" "}
            <p>
              {trackSelections.length}{" "}
              {trackSelections.length === 1 ? "pitch" : "pitches"} selected
            </p>
          </div>
          <div className="ml-auto">
            <Button
              className="border-btn"
              onClick={() => {
                setAccept(false);
                setModalOpen(true);
              }}
            >
              <span className="btn-text icon-suffix">Reject</span>
              <Icon>close</Icon>
            </Button>
            <Button
              onClick={() => {
                setAccept(true);
                setModalOpen(true);
              }}
              className="btn-white"
            >
              <span className="btn-text icon-suffix">Accept</span>
              <Icon>done</Icon>
            </Button>
          </div>
        </div>
      )}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <React.Fragment>
          <FeedbackModal
            tracks={trackSelections}
            accept={accept}
            closeModalOutput={setModalOpen}
            playTrack={playFromModal}
            updateTracks={() => {
              console.log("Close Modal - 1");
              setIsLoadingPlaylists(true);
              setPlaylistSelections([]);
              setPlaylists([]);
              setPageNumber(1);
              getPlaylists(true);
            }}
          ></FeedbackModal>
        </React.Fragment>
      </Modal>
    </div>
  );
}
