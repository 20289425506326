import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";

const ProtectedRoute = ({ element }: any) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="centered-loading mt48">
        <Loading />
      </div>
    );
  }

  if (!isAuthenticated && !isLoading) {
    // Redirect the user to the login page or handle unauthorized access.
    return <Navigate to="/login" />;
  }

  if (user) {
    return element;
  }
};

export default ProtectedRoute;
