import DataService from "./DataService";

const AuthService = {
  getSpotifyToken: async function (url: string, data: object) {
    console.log(url, data);
    return new Promise((res, reject) => {
      DataService.post(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getPayPalCredentials: async function (url: string, data: object) {
    console.log(url, data);
    return new Promise((res, reject) => {
      DataService.post(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updatePassword: async function (url: string, data: object) {
    console.log(url, data);
    return new Promise((res, reject) => {
      DataService.patchAuth(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  put: async function (url: string, params: string) {
    console.log(url, params);
  },
  delete: async function (url: string, params: string) {
    console.log(url, params);
  },
  removeAllTokens: function () {
    console.log("removing tokens");
    localStorage.removeItem("unhurdID");
    localStorage.removeItem("userID");
    localStorage.removeItem("pp-code");
    localStorage.removeItem("sp-id");
    localStorage.removeItem("sp-expireTime");
    localStorage.removeItem("sp-accessToken");
    localStorage.removeItem("sp-refreshToken");
    localStorage.removeItem("auth_token");
  },
};

export default AuthService;
