import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }: any) => {
  console.log("- - - - - auth0 provider - - - - -");

  const audience = "curator-dashboard-api";

  const onRedirectCallback = (appState: any) => {
    // navigate(appState?.returnTo || window.location.pathname);
  };

  let domain;

  if (process.env.REACT_APP_NODE_ENV === "prod") {
    domain = "unhurd.eu.auth0.com";
  } else {
    domain = "test-unhurd.uk.auth0.com";
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
