import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Tooltip,
} from "@mui/material";
import SpotifyService from "../services/SpotifyService";
import PitchesService from "../services/PitchesService";
import { pitchTrack } from "../models/Interfaces/TrackInterfaces";
import AppContext from "../Context/AppContext";
import SpotifyLogin from "./SpotifyLogin";
import PlaylistService from "../services/PlaylistService";

interface IFeedbackModalProps {
  tracks: pitchTrack[];
  accept: boolean | undefined;
  closeModalOutput: (event: boolean) => void;
  playTrack: (track: pitchTrack, pause?: boolean) => void;
  updateTracks: (update: boolean) => void;
}

export default function FeedbackModal({
  tracks,
  closeModalOutput,
  playTrack,
  accept,
  updateTracks,
}: IFeedbackModalProps) {
  const [index, setIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [touched, setTouched] = useState<any>({});
  const [playlistSpotifyTracks, setPlaylistSpotifyTracks] = useState<any[]>([]);
  const [positionIndex, setPositionIndex] = useState<any>(0);
  const [open, setOpen] = useState(false);

  const { dispatchSnackbar } = useContext<any>(AppContext);

  const [productionQuality, setProductionQuality] = useState<
    number | number[] | null
  >();
  const [trackAppeal, setTrackAppeal] = useState<number | number[] | null>();
  const [playlistMoodMatch, setPlaylistMoodMatch] = useState<
    number | number[] | null
  >();
  const [feedbackComment, setFeedbackComment] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [totalTracksInPlaylist, setTotalTracksInPlaylist] = useState<number>(0);

  const closeModal = () => {
    if (isPlaying) {
      playTrack(tracks[index], true);
    }
    updateTracks(true);
    closeModalOutput(false);
  };

  useEffect(() => {
    if (
      totalTracksInPlaylist > playlistSpotifyTracks.length &&
      offset > 0 &&
      totalTracksInPlaylist !== offset
    ) {
      getPlaylistDetails();
    }
  }, [playlistSpotifyTracks, totalTracksInPlaylist, offset]);

  const getPlaylistDetails = (reset?: boolean) => {
    console.log(tracks[index]);
    SpotifyService.getPlaylistTracks(
      tracks[index].playlistSpotifyId,
      reset ? 0 : offset
    )
      .then((resp: any) => {
        console.log(resp);
        const filteredResults = resp.items.filter(
          (item: any) => item.track !== null
        );
        if (reset) {
          setOffset(resp.items.length);
          setPlaylistSpotifyTracks(filteredResults);
        } else {
          setOffset(offset + resp.items.length);
          setPlaylistSpotifyTracks(
            playlistSpotifyTracks.concat(filteredResults)
          );
        }
        setTotalTracksInPlaylist(resp.total);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.error?.status !== 502) {
          if (err.response?.data.statusCode === 404) {
            dispatchSnackbar({
              type: "OPEN_SNACKBAR",
              payload: {
                message: "Couldn't find this playlist in our system",
                type: "",
              },
            });
          }
          if (err.response?.status === 401) {
            setOpen(true);
          }
        }
      });
  };

  useEffect(() => {
    setIndex(0);
  }, [tracks]);

  useEffect(() => {
    setTouched({});
    setOffset(0);
    setPositionIndex(0);
    if (accept) {
      getPlaylistDetails(true);
    }
  }, [index]);

  const acceptTrack = (track: pitchTrack) => {
    setIsLoading(true);
    stopTrack();
    // sendReview(track);

    addToSpotifyPlaylist(track)
      .then((resp: any) => {
        console.log(resp);
        if (resp.validTrackPlacement) {
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message: `${track.track.name} has been added to your playlist`,
              type: "success",
            },
          });
          sendReview(track);
        } else {
          dispatchSnackbar({
            type: "OPEN_SNACKBAR",
            payload: {
              message: `We couldn't find this track on this playlist. Add the track and try again.`,
              type: "error",
            },
          });
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `Oops...something went wrong, try again`,
            type: "error",
          },
        });
        setIsLoading(false);

        if (err.response?.status === 401) {
          setOpen(true);
        }
      });
  };

  const stopTrack = () => {
    if (isPlaying) {
      playTrack(tracks[index], true);
    }
    setIsPlaying(false);
  };

  const addToSpotifyPlaylist = (track: pitchTrack) => {
    const playlistId = track.playlist.spotifyId;
    const spotifyData = {
      uris: [`spotify:track:${track.track.spotifyId}`],
      position: positionIndex,
    };

    // return PlaylistService.verifyTrackPlaylistPlacement(
    //   playlistId,
    //   track.track.spotifyId
    // );
    return SpotifyService.addItemsToPlaylists(playlistId, spotifyData);
  };

  const sendReview = (track: pitchTrack) => {
    const data = {
      accepted: accept,
      curatorSpotifyId: track.playlist.curatorSpotifyId,
      playlistSpotifyId: track.playlist.spotifyId,
      curatorPitchId: track.id,
      artistAccountId: track.artistAccountId,
      feedback: {
        productionQuality: productionQuality,
        trackAppeal: trackAppeal,
        moodMatch: playlistMoodMatch,
        feedback: feedbackComment,
      },
    };

    PitchesService.sendPitchReview(data).then((resp: any) => {
      index === tracks.length - 1 ? closeModal() : setIndex(index + 1);
      // playTrack(tracks[index], true);
      resetSliders();
      setFeedbackComment("");
      dispatchSnackbar({
        type: "OPEN_SNACKBAR",
        payload: {
          message: `Review for ${track.track.name} successfully sent`,
          type: "success",
        },
      });
      setIsLoading(false);
    });
  };

  const rejectTrack = (track: pitchTrack) => {
    setIsLoading(true);
    stopTrack();
    sendReview(track);
  };

  const resetSliders = () => {
    setProductionQuality(0);
    setTrackAppeal(0);
    setPlaylistMoodMatch(0);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setTouched({
      ...touched,
      [name]: value,
    });
  };

  const handlePositionChange = (e: any, v: any) => {
    console.log(v.props.value);
    let ind: number;
    if (v.props.value === "Place at bottom of playlist") {
      ind = totalTracksInPlaylist;
    } else {
      ind = playlistSpotifyTracks?.findIndex(
        (item: any) => item === v.props.value
      );
    }
    setPositionIndex(ind);
  };

  const updateFeedbackComment = (event: any) => {
    setFeedbackComment(event.target.value);
  };

  const isDisabled = () => {
    return (
      touched.productionQuality === undefined ||
      touched.trackAppeal === undefined ||
      touched.playlistMoodMatch === undefined ||
      isLoading
    );
  };

  const handleLoginClose = (action: boolean) => {
    if (action) {
      console.log("logging in to spotify");
    }
    setOpen(false);
  };

  return (
    <div className="feedback-modal-container">
      <Dialog open={open} onClose={handleLoginClose} className="text-center">
        <h4 data-testid="not-logged-into-spotify text-center">
          In order to add this track to your playlist you need to be logged in
          to Spotify.
          <br></br>
          Would you like to log in?
        </h4>
        <div className="d-flex jc-center mt16">
          <Button
            className="border-btn"
            onClick={() => {
              handleLoginClose(false);
            }}
            data-testid="refuse-spotify-login"
          >
            No thanks
          </Button>{" "}
          <SpotifyLogin login={true} />
        </div>
      </Dialog>
      <div className="max-w200 m-auto mt48">
        {" "}
        <p className="text-blue">
          Song {index + 1} of {tracks.length}
        </p>
        <Slider value={(100 / tracks.length) * (index + 1)}></Slider>
      </div>
      <Button className="icon-btn add-playlist-close-btn" onClick={closeModal}>
        <Icon>close</Icon>
      </Button>

      <h1 className="mt48">Give us feedback</h1>
      <p className="text-faded mt16">
        This track was submitted by{" "}
        <span className="text-white">{tracks[index].artist.name}</span> to the{" "}
        <span className="text-white">{tracks[index].playlist.name}</span>{" "}
        playlist
      </p>
      <div className="title-card m-auto max-w400 d-flex mt32 playback-pic">
        <img
          src={
            tracks[index].track?.image
              ? tracks[index].track?.image
              : "images/logos/no-image-available.svg"
          }
          alt=""
        />
        <div className="text-left">
          <p className="small">{tracks[index].track?.name}</p>
          <p className="small text-faded">
            {tracks[index].track?.artists?.map(
              (artist: any, artistIndex: number) =>
                artistIndex === tracks[index].track?.artists.length - 1
                  ? artist.name
                  : artist.name + ", "
            ) || "N/A"}
          </p>
        </div>
        <div className="ml-auto">
          <Button className="icon-btn  mt-auto mb-auto">
            {!isPlaying && (
              <Icon
                onClick={() => {
                  playTrack(tracks[index]);
                  setIsPlaying(true);
                }}
                className="material-symbols-outlined"
              >
                play_circle
              </Icon>
            )}
            {isPlaying && (
              <Icon
                onClick={() => {
                  if (isPlaying) {
                    playTrack(tracks[index], true);
                  }
                  setIsPlaying(false);
                }}
                className="material-symbols-outlined"
              >
                stop_circle
              </Icon>
            )}
          </Button>
          <Tooltip title="View on Spotify" arrow placement="top">
            <Button
              className="icon-btn ml0"
              onClick={() => {
                window.open(tracks[index].track.url);
              }}
            >
              <img src="/images/spotify-logo.png" alt="spot-logo"></img>
            </Button>
          </Tooltip>
        </div>
      </div>
      {accept && playlistSpotifyTracks && playlistSpotifyTracks.length > 0 && (
        <FormControl className="mt32">
          <InputLabel id="playlist-track-select-label">
            Place above this track
          </InputLabel>
          <Select
            value={
              positionIndex === totalTracksInPlaylist
                ? "Place at bottom of playlist"
                : playlistSpotifyTracks[positionIndex]
            }
            label="Track Position"
            onChange={handlePositionChange}
          >
            {playlistSpotifyTracks.map((item: any, index: number) => (
              <MenuItem key={index} value={playlistSpotifyTracks[index]}>
                <div className="d-flex w-100">
                  <div>
                    <p className="small">{item.track?.name}</p>
                    <p className="small text-faded p0">
                      {item.track?.artists?.map((artist: any, index: number) =>
                        index === item.track?.artists.length - 1
                          ? artist.name
                          : artist.name + ", "
                      ) || "N/A"}
                    </p>
                  </div>
                  <div className="ml-auto pl16">
                    <p className="small text-faded">Position</p>
                    <p className="small text-right p0">{index + 1}</p>
                  </div>
                </div>
              </MenuItem>
            ))}
            <MenuItem
              key={totalTracksInPlaylist}
              value={"Place at bottom of playlist"}
            >
              <p>Place at bottom of playlist</p>
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <form className="max-w400 m-auto mt32">
        <div className="mb16">
          <div className="d-flex jc-center">
            <p className="small">Production Quality</p>
            <Tooltip title="The production quality measure indicates whether you think the track has been mixed and mastered to a professional standard. Do all of the different musical elements work well together and meet your expectations.">
              <Icon className="material-symbols-outlined pl8 fs-16 mt4">
                info
              </Icon>
            </Tooltip>
          </div>
          <Slider
            defaultValue={0}
            step={1}
            max={5}
            min={0}
            valueLabelDisplay="auto"
            value={productionQuality || 0}
            name="productionQuality"
            onChange={(e: any, value: number | number[]) => {
              handleChange(e);
              setProductionQuality(value);
            }}
          ></Slider>
          <div className="d-flex jc-space-between mt-8">
            <p className="small">Low</p>
            <p className="small">High</p>
          </div>
        </div>
        <div className="mb16">
          <div className="d-flex jc-center">
            <p className="small">Track Appeal</p>
            <Tooltip title="Does the song appeal to the audience that it’s created for, does the track have good repeat play value according to your musical knowledge.">
              <Icon className="material-symbols-outlined pl8 fs-16 mt4">
                info
              </Icon>
            </Tooltip>
          </div>
          <Slider
            defaultValue={0}
            step={1}
            max={5}
            min={0}
            valueLabelDisplay="auto"
            value={trackAppeal || 0}
            name="trackAppeal"
            onChange={(e: any, value: number | number[]) => {
              handleChange(e);
              setTrackAppeal(value);
            }}
          ></Slider>
          <div className="d-flex jc-space-between mt-8">
            <p className="small">Low</p>
            <p className="small">High</p>
          </div>
        </div>
        <div className="mb16">
          <div className="d-flex jc-center">
            <p className="small">Playlist Mood Match</p>
            <Tooltip title="Does the song fit in with the vibe and emotion that you’re trying to create with the playlist. It may match by genre, but does it match by mood and feel.">
              <Icon className="material-symbols-outlined pl8 fs-16 mt4">
                info
              </Icon>
            </Tooltip>
          </div>
          <Slider
            defaultValue={0}
            step={1}
            max={5}
            min={0}
            valueLabelDisplay="auto"
            value={playlistMoodMatch || 0}
            name="playlistMoodMatch"
            onChange={(e: any, value: number | number[]) => {
              handleChange(e);
              setPlaylistMoodMatch(value);
            }}
          ></Slider>
          <div className="d-flex jc-space-between mt-8">
            <p className="small">Low</p>
            <p className="small">High</p>
          </div>
        </div>

        <div>
          <label>
            <p className="small text-center">Comments</p>
          </label>
          <textarea
            onChange={updateFeedbackComment}
            placeholder="This will be sent directly to the artist - please be reasonable but constructive"
          />
        </div>
        {/* )} */}
      </form>

      <br />
      {accept ? (
        <Button
          className="btn-white"
          disabled={isLoading}
          onClick={() => {
            acceptTrack(tracks[index]);
          }}
        >
          <span>Confirm track added to playlist</span>
        </Button>
      ) : (
        <Button
          className="btn-white"
          onClick={() => {
            rejectTrack(tracks[index]);
          }}
          disabled={isDisabled()}
        >
          <span className="btn-text">Reject & send feedback</span>
        </Button>
      )}
    </div>
  );
}
