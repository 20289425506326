import moment from "moment";

export const dateTimeFormatter = (value: string, format?: string) => {
  const date = moment(value).format("DD/MM/YY");
  const time = moment(value).format("LT");
  if (format === "date") {
    return date;
  }

  return `${date} at ${time}`;
};
