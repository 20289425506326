import React from "react";

export default function Footer({ size }: { size?: string }) {
  return (
    <>
      <div className="footer">
        <div className="d-flex jc-space-between">
          <div className="">
            <p className="text-faded">Powered by</p>
            <img
              src="/images/logos/Spotify_Logo_RGB_Green.png"
              alt="spotify-logo"
            ></img>
          </div>
          <div className=" text-right">
            <a
              className="text-faded"
              href="https://www.unhurd.co.uk/partnerterms"
              target="blank"
            >
              Terms and Conditions
            </a>
            <p className="small text-faded">
              un:hurd, Unit 37, Tileyard Road, London, N7 9AH, UK
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
