import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Logout from "./Logout";
import { Icon } from "@mui/material";

export default function NavBar() {
  const navigate = useNavigate();
  const [minimize, setMinimize] = useState(false);
  const navItems = [
    { title: "Dashboard", icon: "dashboard", navLink: "/dashboard" },
    { title: "Playlists", icon: "playlists", navLink: "/playlists" },
    { title: "Settings", icon: "settings", navLink: "/settings" },
  ];

  const toggleNav = () => {
    setMinimize(!minimize);
  };

  return (
    <>
      <div className={minimize ? "nav-container minimized" : "nav-container"}>
        <img
          className="cursor-pointer logo-img"
          src={
            minimize
              ? "/images/logos/unhurd-logo.png"
              : "/images/logos/full-text-logo.svg"
          }
          alt="unhurd-logo"
          onClick={() => {
            navigate("/");
          }}
        ></img>

        {!minimize && (
          <div className="minimize-arrow" onClick={toggleNav}>
            <Icon>chevron_left</Icon>
          </div>
        )}
        {minimize && (
          <div className="minimize-arrow" onClick={toggleNav}>
            <Icon>chevron_right</Icon>
          </div>
        )}

        <nav className="nav-sidebar">
          {navItems.map((item) => {
            return (
              <div
                key={item.title}
                className="min-h48"
                data-testid={`nav-bar-item-${item.icon}`}
              >
                <NavLink
                  className={
                    minimize ? "nav-link-item minimized" : "nav-link-item"
                  }
                  to={item.navLink}
                >
                  {item.icon === "playlists" ? (
                    <img
                      className="nav-link-icon"
                      src="/images/icons/PlaylistIcon.svg"
                      alt=""
                    ></img>
                  ) : (
                    <Icon className="nav-link-icon material-symbols-outlined">
                      {item.icon}
                    </Icon>
                  )}

                  <span
                    className={
                      minimize ? "nav-link-text minimize" : "nav-link-text"
                    }
                  >
                    {item.title}
                  </span>
                </NavLink>
              </div>
            );
          })}
        </nav>
        <div className={minimize ? "nav-footer minimize" : "nav-footer"}>
          <Logout minimize={minimize} />
        </div>
      </div>
    </>
  );
}
