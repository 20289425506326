import DataService from "./DataService";

const MetricsService = {
  getReviewsOverTime: async function (curatorUnhurdId?: string) {
    const url = `metrics/${curatorUnhurdId}/pitch-responses`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getConnectedPlaylistStats: async function (
    curatorUnhurdId: string | undefined,
    userID: string | undefined
  ) {
    const url = `metrics/${curatorUnhurdId}/${userID}/connected-playlists`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getPendingPitchesStats: async function (curatorUnhurdId: string | undefined) {
    const url = `metrics/${curatorUnhurdId}/pending-pitches`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default MetricsService;
