import DataService from "./DataService";

const PitchesService = {
  getPlaylistPitches: async function (
    playlistId: string,
    pageSize: number,
    status: string,
    contToken: string
  ) {
    const url = `pitch/${playlistId}?pageSize=${pageSize}&status=${status}&continuationToken=${contToken}`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  sendPitchReview: async function (data: any) {
    const url = `pitch/review-pitch`;
    return new Promise((res, reject) => {
      DataService.put(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  seedPitches: async function (id: any) {
    const url = `${id}/pitches?onlyDeletePending=true`;
    return new Promise((res, reject) => {
      DataService.postSeedPitches(url, {})
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default PitchesService;
