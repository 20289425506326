import React, { useEffect, useRef, useState } from "react";
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { User } from "../models/Interfaces/UserInterfaces";
import TransactionService from "../services/TransactionService";
import {
  TransactionInterface,
  TransactionItemInterface,
} from "../models/Interfaces/TransactionInterface";
import TransactionType from "../components/TransactionType";
import StatusBadges from "../components/StatusBadges";
import { dateTimeFormatter } from "../formatters/DateTimeFormatter";
import TotalBalanceCard from "../components/TotalBalanceCard";
import Loading from "../components/Loading";

export default function TotalBalancePage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  document.title = "Total balance";
  const [transactions, setTransactions] = useState<TransactionItemInterface[]>(
    []
  );
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [contToken, setContToken] = useState<string | null>("");

  const columns: string[] = [
    "#",
    "Transaction",
    "Amount",
    "Date/Time",
    "Track Info",
    "Playlist",
    "Accepted/Rejected",
    "Status",
  ];

  const navigate = useNavigate();
  const ref = useRef<any>(null);

  const getTransactions = () => {
    if (unhurdUser) {
      TransactionService.getTransactions(
        unhurdUser.id,
        20,
        "",
        contToken || ""
      ).then((resp: TransactionInterface) => {
        console.log(resp);
        setTransactions(transactions.concat(resp.items));
        setTotalTransactions(resp.total);
        setContToken(resp?.urlEncodedContinuationToken);
        setIsLoadingMore(false);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (unhurdUser) {
      getTransactions();
    }
  }, [unhurdUser]);

  const canPage = (entries: any) => {
    return (
      entries[0].isIntersecting &&
      !isLoadingMore &&
      transactions.length < totalTransactions
    );
  };

  useEffect(() => {
    if (transactions.length > 0) {
      const observer = new IntersectionObserver((entries: any) => {
        if (canPage(entries)) {
          setIsLoadingMore(true);
          getTransactions();
        }
      }, {});
      observer.observe(ref.current);
    }
  }, [transactions]);

  return (
    <div className="page-content">
      <p
        className="text-faded cursor-pointer d-flex"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <Icon>chevron_left</Icon>
        <span className="pt2">Dashboard</span>
      </p>
      <h2>Total balance</h2>
      <p className="text-faded">
        You can review all of your pending pitches below and decide whether you
        want to either accept or reject them onto your playlist.
      </p>
      <div className="top-cards " data-testid="total-progress-card">
        <div className="title-card">
          <TotalBalanceCard unhurdUser={unhurdUser} />
        </div>
      </div>
      {/* <div className="top-cards">
        <div className="title-card" data-testid="fee-per-sub-card">
          <div className="card-title">
            <div className="titles">
              <div className="text-faded">Fee per submission</div>
              <div className="text-number">£3.00</div>
            </div>
          </div>
          <div className="mt16">
            <p className="text-faded">
              This is how much you currently make from each submission.
            </p>
          </div>
        </div>
        <div className="title-card" data-testid="pay-date-card">
          <div className="card-title">
            <div className="titles">
              <div className="text-faded">Your next pay date is in</div>
              <div className="text-number">21 days</div>
            </div>
          </div>
          <div className="mt16">
            <p className="text-faded">
              Your next pay date will be on the 21st June 2023.
            </p>
          </div>
        </div>
      </div> */}
      {/* Transaction Table */}
      {transactions.length > 0 && !isLoading && (
        <div className="playlist-card mt16">
          <div className="d-flex">
            <h3>Transaction History</h3>
            {totalTransactions && (
              <p className="pt6 ml-auto text-faded">
                {totalTransactions} transactions in total
              </p>
            )}
          </div>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
                <col width="1%" />
                <col width="8%" />
                <col width="2%" />
                <col width="8%" />
                <col width="10%" />
                <col width="10%" />
                <col width="5%" />
                <col width="5%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.length > 0 &&
                  transactions?.map(
                    (row: TransactionItemInterface, index: number) => (
                      <TableRow
                        key={index}
                        ref={index === transactions.length - 1 ? ref : null}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "--animation-number": index % 20,
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell className="pt16 pb16">
                          <TransactionType status={row?.type} />
                        </TableCell>
                        <TableCell>£{row?.amount.toFixed(2)}</TableCell>
                        <TableCell>
                          {dateTimeFormatter(row?.updatedAt)}
                        </TableCell>
                        <TableCell>
                          <p className="small p0">
                            {row?.metadata?.pitchMetadata?.trackName}
                          </p>
                          <p className="small text-faded p0">
                            {row?.metadata?.pitchMetadata?.artistName}
                          </p>
                        </TableCell>
                        <TableCell>
                          {row?.metadata?.pitchMetadata?.playlistName}
                        </TableCell>

                        <TableCell>
                          {row?.metadata?.pitchMetadata?.decision && (
                            <div>
                              <StatusBadges
                                status={
                                  row?.metadata?.pitchMetadata?.decision === 2
                                    ? "rejected"
                                    : "accepted"
                                }
                              ></StatusBadges>
                            </div>
                          )}
                        </TableCell>

                        <TableCell>
                          <StatusBadges
                            type="transaction"
                            status={
                              row?.status === 1
                                ? "pending"
                                : row?.status === 2
                                ? "verified"
                                : "rejected"
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoadingMore && <div className="text-center">Loading more...</div>}
        </div>
      )}
      {transactions.length === 0 && !isLoading && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-pitches-available">No transactions yet</h4>
        </div>
      )}
      {isLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </div>
  );
}
