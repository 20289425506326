import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import AreaChartComponent from "./charts/AreaChart";
import LineChartComponent from "./charts/LineChart";
import MetricsService from "../services/MetricsService";
import { User } from "../models/Interfaces/UserInterfaces";
import Loading from "./Loading";
import moment from "moment";

export default function ChartCard({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const [chartValue, setChartValue] = useState<string>("area");
  const [userHasPitches, setUserHasPitches] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalPitchesReviewed, setTotalPitchesReviewed] = useState<number>(0);

  const changeChart = (e: any, value: any) => {
    if (value !== null) {
      setChartValue(value);
    }
  };

  const getMetricData = () => {
    MetricsService.getReviewsOverTime(unhurdUser?.id).then((resp: any) => {
      console.log(resp);
      setTotalPitchesReviewed(resp.total);
      if (resp.stats.length === 0) {
        setUserHasPitches(false);
      }
      setChartData(resp.stats.reverse());
      const initialPoint = {
        date: moment().subtract(1, "days").format(),
        accepted: 0,
        rejected: 0,
        total: 0,
      };
      if (resp.stats.length === 1) {
        setChartData([initialPoint].concat(resp.stats));
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (unhurdUser) {
      getMetricData();
    }
  }, [unhurdUser]);

  return (
    <>
      <div className="card-title">
        <div className="card-icon material-icons">
          <img src="/images/icons/PitchingIcon.svg" alt=""></img>
        </div>
        <div className="titles">
          <div className="text-faded">Total pitches reviewed</div>
          <div className="text-number">{totalPitchesReviewed}</div>
        </div>
        {userHasPitches && (
          <ToggleButtonGroup
            className="ml-auto"
            color="primary"
            value={chartValue}
            exclusive
            onChange={changeChart}
            aria-label="Platform"
          >
            <ToggleButton value="area">
              <Tooltip title="Area Chart">
                <Icon>area_chart</Icon>
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="line">
              <Tooltip title="Line Chart">
                <Icon>show_chart</Icon>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
      <div className="pos-rel">
        {isLoading && (
          <div className="centered-loading mt48">
            <Loading />
          </div>
        )}
        {!isLoading && (
          <div className="pt16 pb16">
            {chartValue === "area" && chartData?.length > 0 && (
              <AreaChartComponent data={chartData} />
            )}
            {chartValue === "line" && chartData?.length > 0 && (
              <LineChartComponent data={chartData} />
            )}
          </div>
        )}
        {!userHasPitches && (
          <div className="overlay-blur">
            <p className="text-faded mb16 mt100 pl16 pr16">
              It looks like you haven’t reviewed any pitches yet. Once you have
              reviewed pitches, they will appear here.
            </p>
          </div>
        )}
      </div>
    </>
  );
}
