import { TransactionInterface } from "../models/Interfaces/TransactionInterface";
import DataService from "./DataService";

const TransactionService = {
  getTransactions: async function (
    id: string,
    pageSize: number,
    status: string,
    contToken: string
  ) {
    const url = `transaction/${id}?pageSize=${pageSize}&status=${status}&continuationToken=${contToken}`;
    return new Promise<TransactionInterface>((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default TransactionService;
