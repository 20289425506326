import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import UserService from "../../services/UserService";
import Logout from "../Logout";
import OnboardingContext from "../../Context/OnboardingContext";
import { Button } from "@mui/material";
import Loading from "../Loading";

export default function VerifyEmail() {
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
  const { user, isLoading } = useAuth0();
  const { dispatch } = useContext<any>(OnboardingContext);

  const resendEmail = () => {
    if (user?.sub) {
      UserService.resendEmailVerification(user.sub).then((resp) => {
        console.log(resp);
      });
    }
  };

  const createUser = (user: any) => {
    UserService.createUser(
      user.sub,
      user.email,
      user.given_name || "",
      user.family_name || "",
      false
    ).then((resp: any) => {
      console.log(resp);
      localStorage.setItem("userID", resp.userId);
      localStorage.setItem("unhurdID", resp.id);
      dispatch({
        type: "PAGE NEXT",
      });
      setIsLoadingUser(false);
    });
  };

  useEffect(() => {
    console.log(user);
    if (user) {
      if (user?.email_verified) {
        console.log("user email verified");
        console.log(user.email);
        UserService.getUserByID(user.sub)
          .then((resp) => {
            console.log(resp);
            if (resp === undefined) {
              console.log("NO USER IN OUR DATABASE");
              if (user) {
                createUser(user);
              }
            } else if (resp) {
              dispatch({
                type: "PAGE NEXT",
              });
              setIsLoadingUser(false);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 404) {
              console.log("NO USER IN OUR DATABASE");
              if (user) {
                createUser(user);
              }
            }
            setIsLoadingUser(false);
          });
      } else {
        setIsLoadingUser(false);
      }
    }
  }, [user, isLoading]);

  return (
    <>
      {!isLoadingUser && (
        <div className="text-center mt120" data-testid="verify-email-component">
          <h1 className="">Verify your email address</h1>
          <h3 className="text-faded mt32">
            Before we get started, we just need you to verify your email
            address.
          </h3>
          <h3 className="text-faded">
            We’ve sent a code to{" "}
            <span className="text-blue">{user?.email}</span>
          </h3>
          <img className="mt48" src="/images/email-icon.svg" alt="email"></img>
          <div></div>
          <Button className="btn-white mt32" onClick={resendEmail}>
            Resend verification email
          </Button>
          <div className="onboarding-nav-container">
            <div className="onboarding-nav-buttons">
              <div className="ml-auto">
                <Logout />
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div
          className="centered-loading mt48"
          data-testid="verify-email-loading"
        >
          <Loading />
        </div>
      )}
    </>
  );
}
