import React, { useEffect, useState } from "react";

export interface ISpotifyWebPlayerProps {
  deviceId?: any;
  currentTrack?: any;
  currentState?: any;
}

declare global {
  interface Window {
    onSpotifyWebPlaybackSDKReady: any;
    Spotify: any;
  }
}

export default function SpotifyWebPlayer({
  deviceId,
  currentTrack,
  currentState,
}: ISpotifyWebPlayerProps) {
  const [player, setPlayer] = useState<any>(undefined);
  const [deviceID, setDeviceID] = useState(undefined);
  const [nowPlaying, setNowPlaying] = useState<any>();

  useEffect(() => {
    if (deviceID) {
      deviceId(deviceID);
    }
    return () => {
      fadeOut();
    };
  }, [deviceID, deviceId]);

  const fadeOut = () => {
    player?.getVolume().then((resp: any) => {
      if (resp > 0.1) {
        player?.setVolume(resp - 0.1);
        setTimeout(fadeOut, 100);
      } else {
        player?.pause();
      }
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.scdn.co/spotify-player.js";
    script.async = true;

    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
      const player = new window.Spotify.Player({
        name: "Web Playback SDK",
        getOAuthToken: (cb: any) => {
          cb(localStorage.getItem("sp-accessToken"));
        },
        volume: 1,
      });

      setPlayer(player);

      player.addListener("ready", ({ device_id }: any) => {
        console.log("Ready with Device ID", device_id);
        setDeviceID(device_id);
      });

      player.addListener("not_ready", ({ device_id }: any) => {
        console.log("Device ID has gone offline", device_id);
      });

      player.addListener("player_state_changed", (state: any) => {
        if (!state) {
          return;
        }

        const current_track = state.track_window.current_track;
        currentTrack(current_track);
        setNowPlaying(current_track);
        currentState(state);
        if (
          state.track_window.previous_tracks.find(
            (x: any) => x.id === state.track_window.current_track.id
          ) &&
          state.paused
        ) {
          console.log("track ended");
          currentState("TRACK_ENDED");
        }
      });
      player.setName("Unhurd Spotify Player");

      player.connect();

      player.getCurrentState().then((state: any) => {
        console.log("Current state: ", state);
      });
    };
    return () => {
      currentState("TRACK_ENDED");
      player?.getCurrentState().then((state: any) => {
        console.log("Current state: ", state);
      });
      player?.pause();
      player?.disconnect();
    };
  }, []);

  return <></>;
}
