import React from "react";

export default function Loading({ size }: { size?: string }) {
  return (
    <>
      {size && size === "small" ? (
        <div
          className="loading-container loading-small"
          data-testid="loading-component-small"
        >
          <div className="circle circle-small circle-1"></div>
          <div className="circle circle-small circle-2"></div>
        </div>
      ) : (
        <div className="loading-container" data-testid="loading-component">
          <div className="circle circle-1"></div>
          <div className="circle circle-2"></div>
        </div>
      )}
    </>
  );
}
