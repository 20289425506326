import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Icon } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import PlaylistService from "../services/PlaylistService";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StatusBadges from "../components/StatusBadges";
import { User } from "../models/Interfaces/UserInterfaces";
import { TableSortLabel } from "@mui/material";
import MetricsService from "../services/MetricsService";

export default function PlaylistsPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [isLoadingPlaylists, setIsLoadingPlaylists] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("pending-pitches");
  const [order, setOrder] = useState<"desc" | "asc" | undefined>("desc");
  const [numberOfPendingPitches, setNumberOfPendingPitches] =
    useState<number>(0);

  const ref = useRef<any>(null);

  const navigate = useNavigate();
  document.title = "Playlists";

  const columns: any[] = [
    { name: "#", id: "number" },
    { name: "Playlist Title", id: "title" },
    // { name: "Followers", id: "followers" },
    { name: "Song Count", id: "track-count" },
    { name: "Status", id: "status" },
    { name: "No. of pending pitches", id: "pending-pitches" },
  ];

  const getPlaylists = (sort?: string, orderBy?: string) => {
    console.log(sortBy);
    PlaylistService.getConnectedUserPlaylists(
      unhurdUser?.spotifyAccounts[0].id,
      20,
      "",
      sort || sortBy,
      orderBy || order,
      sort ? 1 : pageNumber
    ).then((resp: any) => {
      console.log(resp);
      setTotalPages(resp.totalPages);
      setTotalItems(resp.totalItems);

      setIsLoadingPlaylists(false);
      if (sort) {
        setPlaylists(resp?.items);
      } else {
        setPlaylists(playlists.concat(resp?.items));
        setPageNumber(pageNumber + 1);
      }
      setIsLoadingMore(false);
    });
  };

  useEffect(() => {
    if (unhurdUser) {
      getPlaylists();
      getPitchesStats();
    }
  }, [unhurdUser]);

  const canPage = (entries: any) => {
    return (
      entries[0].isIntersecting &&
      !isLoadingMore &&
      pageNumber <= totalPages &&
      entries[0].target.rowIndex <= playlists.length
    );
  };

  const getPitchesStats = () => {
    MetricsService.getPendingPitchesStats(unhurdUser?.id)
      .then((resp: any) => {
        console.log(resp);
        setNumberOfPendingPitches(resp.pendingPitches);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log(playlists);
    if (playlists.length > 0) {
      const observer = new IntersectionObserver((entries: any) => {
        console.log(entries[0]);
        console.log(pageNumber);
        if (canPage(entries)) {
          console.log(entries[0]);
          console.log(entries[0].isIntersecting);
          setIsLoadingMore(true);
          getPlaylists();
        }
      }, {});
      observer.observe(ref.current);
    }
  }, [playlists]);

  return (
    <div className="page-content">
      <div className="d-flex jc-space-between mb16">
        <h2 data-testid="your-playlist-title">Your playlists</h2>
        <Button
          onClick={() => {
            navigate("/add-playlist");
          }}
          className="btn-white min-w180 mr0"
          data-testid="add-new-playlist-button"
        >
          <span className="btn-text icon-suffix">Add new playlist</span>
          <Icon>add</Icon>
        </Button>
      </div>
      <div className="top-cards">
        <div className="title-card">
          <div className="card-title">
            <div className="card-icon material-icons">
              <img src="/images/icons/PlaylistIcon.svg" alt=""></img>
            </div>
            <div className="titles">
              <div className="text-faded">Playlists connected</div>
              <div className="text-number">{totalItems}</div>
            </div>
          </div>
        </div>
        <div className="title-card">
          <div className="card-title">
            <div className="card-icon material-icons">
              <img src="/images/icons/PitchingIcon.svg" alt=""></img>
            </div>
            <div className="titles">
              <div className="text-faded">Pending pitches</div>
              <div className="text-number">{numberOfPendingPitches}</div>
            </div>
          </div>
        </div>
      </div>
      {!isLoadingPlaylists && playlists.length > 0 && (
        <div className="playlist-card mt16">
          <h3>Connected playlists</h3>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
                <col width="1%" />
                <col width="30%" />
                <col width="10%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      data-testid={`column-name-${column.id}`}
                    >
                      <TableSortLabel
                        active={sortBy === column.id}
                        direction={order}
                        onClick={() => {
                          setSortBy(column.id);
                          setOrder(
                            column.id === sortBy
                              ? order === "desc"
                                ? "asc"
                                : "desc"
                              : order
                          );
                          setIsLoadingPlaylists(true);
                          getPlaylists(
                            column.id,
                            column.id === sortBy
                              ? order === "desc"
                                ? "asc"
                                : "desc"
                              : order
                          );
                        }}
                        IconComponent={SortIcon}
                      >
                        <div className={column.name === "#" ? "pl16" : ""}>
                          {column.name}
                        </div>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {playlists?.map((row, index) => (
                  <TableRow
                    key={index}
                    data-testid={`playlist-table-item-${index}`}
                    ref={index === playlists.length - 1 ? ref : null}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "--animation-number": index % 20,
                    }}
                  >
                    <TableCell className="pl16" width={5}>
                      {index + 1}
                    </TableCell>
                    <TableCell width="30%">
                      <div className="table-item-with-image">
                        <img
                          src={
                            row.images?.length > 0
                              ? row.images[0]?.url
                              : "images/logos/no-image-available.svg"
                          }
                          alt=""
                        />
                        <span>{row.name}</span>
                      </div>
                    </TableCell>
                    {/* <TableCell>{row.followers || 0}</TableCell> */}
                    <TableCell>{row.trackCount}</TableCell>
                    <TableCell>
                      <StatusBadges
                        status={
                          row.statusInfo?.status === 0
                            ? "rejected"
                            : row.statusInfo?.status === 1
                            ? "pending"
                            : "verified"
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <div className="table-end-call-with-button">
                        <span>{row.stats?.pendingPitches || 0}</span>
                        <Button
                          onClick={() => {
                            navigate(`/playlists/${row.id}`);
                          }}
                          className="btn-white"
                          data-testid={`playlist-table-item-${index}-button`}
                        >
                          <span className="btn-text icon-suffix">Review</span>
                          <Icon>chevron_right</Icon>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoadingMore && <div className="text-center">Loading more...</div>}
        </div>
      )}

      {!isLoadingPlaylists && playlists.length === 0 && (
        <div className="text-center title-card mt16">
          <h4>You've not connected any playlists yet</h4>
        </div>
      )}
      {isLoadingPlaylists && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </div>
  );
}
