import React from "react";
import { Icon } from "@mui/material";

export type TransactionStatusType = 0 | 1 | 2;

export interface ITransactionTypeProps {
  status: TransactionStatusType;
}

export default function TransactionType({ status }: ITransactionTypeProps) {
  return (
    <>
      {status === 1 && (
        <div className="d-flex transaction-type" data-testid="paypal-payment">
          <Icon className="text-white">payments</Icon>
          <span className="pt2 pl8">PayPal Payment</span>
        </div>
      )}
      {status === 2 && (
        <div className="d-flex transaction-type" data-testid="playlist-pitch">
          <img src="/images/icons/PitchingIcon.svg" alt=""></img>
          <span className="pt2 pl8">Playlist Pitch</span>
        </div>
      )}
    </>
  );
}
