import { User } from "@auth0/auth0-react";
import { Button, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MetricsService from "../services/MetricsService";

export default function PendingPitchesCard({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const [numberOfPendingPitches, setNumberOfPendingPitches] =
    useState<number>(0);
  const [numberOfDistinctArtists, setNumberOfDistinctArtists] =
    useState<number>(0);
  const [numberOfDistinctTracks, setNumberOfDistinctTracks] =
    useState<number>(0);

  const navigate = useNavigate();

  const getPitchesStats = () => {
    MetricsService.getPendingPitchesStats(unhurdUser?.id)
      .then((resp: any) => {
        console.log(resp);
        setNumberOfPendingPitches(resp.pendingPitches);
        setNumberOfDistinctTracks(resp.distinctTracks);
        setNumberOfDistinctArtists(resp.distinctArtists);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (unhurdUser) {
      getPitchesStats();
    }
  }, [unhurdUser]);

  return (
    <>
      <div
        className="title-card cursor-pointer"
        onClick={() => {
          navigate("pendingpitches");
        }}
      >
        <div className="card-title">
          <div className="card-icon material-icons">
            <img src="/images/icons/PitchingIcon.svg" alt=""></img>
          </div>
          <div className="titles">
            <div className="text-faded">Pending pitches</div>
            <div className="text-number">{numberOfPendingPitches}</div>
          </div>
          <Button
            className="icon-btn ml-auto"
            onClick={() => {
              navigate("pendingpitches");
            }}
            data-testid="go-to-pending-pitches-button"
          >
            <Icon>chevron_right</Icon>
          </Button>
        </div>
        {numberOfPendingPitches === 0 && (
          <div className="text-faded mt16">
            It looks like you have{" "}
            <span className="text-white">{numberOfPendingPitches}</span> pitches
            to review. Once you receive pitches from artists at unhurd, they
            will appear here.
          </div>
        )}
        {numberOfPendingPitches > 0 && (
          <div className="text-faded mt16">
            You have{" "}
            <span className="text-white">{numberOfDistinctTracks}</span>{" "}
            distinct tracks to review, by{" "}
            <span className="text-white">{numberOfDistinctArtists}</span>{" "}
            different artists, in{" "}
            <span className="text-white">{numberOfPendingPitches}</span>{" "}
            separate pitches. Get reviewing to get paid!
          </div>
        )}
      </div>
    </>
  );
}
