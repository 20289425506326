import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../services/AuthService";
import { User } from "../models/Interfaces/UserInterfaces";
import moment from "moment";
import Loading from "../components/Loading";
import { Button } from "@mui/material";

export default function SpotifyCallbackPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const [params] = useSearchParams();
  const [error, setError] = useState<any>(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const navigate = useNavigate();
  const code: string | null = params.get("code");

  useEffect(() => {
    console.log("in useEffect");
    if (code) {
      getToken();
    }
  }, []);

  const getToken = async () => {
    const url = "auth/spotify";
    const data = {
      code: code,
      redirectUri: `${window.location.origin}/spotify-callback`,
    };
    console.log(document.referrer);
    await AuthService.getSpotifyToken(url, data)
      .then((resp: any) => {
        console.log(resp);
        const expires = moment().seconds(resp.expiresIn).format();
        console.log(expires);
        localStorage.setItem("sp-accessToken", resp.value);
        localStorage.setItem("sp-refreshToken", resp.refreshToken);
        localStorage.setItem("sp-expireTime", expires);
        console.log(unhurdUser);
        if (
          unhurdUser &&
          unhurdUser?.accountConfiguration?.onboardingStage < 5
        ) {
          navigate("/onboarding", { state: "goToSpotifyConnect" });
        }
        navigate(-1);
      })
      .catch((err) => {
        navigate(-1);
      });
  };

  return (
    <>
      <div className="onboarding-page">
        {!error && (
          <div className="centered-loading mt48">
            <Loading />
          </div>
        )}
        {error && (
          <div className="centered-loading mt48">
            <div>
              <img
                className="mt48"
                src="/images/spotify-logo.png"
                alt="spotify-logo"
              ></img>
              <h1>We're sorry</h1>
              <h4>Something went wrong connecting to Spotify</h4>
              <Button
                className="btn-white mt48"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
