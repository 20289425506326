import * as React from "react";

export default function ScreenSizePlaceholder() {
  return (
    <div className="small-screen-placeholder landing-page">
      <img src="/images/logos/full-text-logo.svg" alt="" />
      <div className="d-flex flex-d-col inner-items">
        <img src="/images/icons/ScreenIcon.svg" alt="" />
        <h2 className="mt32">Coming soon...</h2>
        <p className="text-faded mt16">
          We don't currently support mobile devices. Please use a desktop/laptop
          in full-screen view to continue.
        </p>
      </div>
    </div>
  );
}
