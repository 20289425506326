import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/ApplicationInsightsService";
import { hotjar } from "react-hotjar";
import { Auth0ProviderWithNavigate } from "./components/Auth0ProviderWithHistory";

hotjar.initialize(3591769, 6);

export const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const currentDomain = window.location.href;
if (currentDomain.includes("127.0.0.1:3000")) {
  const newDomain = currentDomain.replace("127.0.0.1:3000/", "localhost:3000/");
  window.location.assign(newDomain);
}

if (process.env.REACT_APP_NODE_ENV === "prod") {
  console.log = () => {};
}

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </AppInsightsContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
