import { Button } from "@mui/material";
import React from "react";

export interface ISpotifyLoginProps {
  login?: boolean;
  force?: boolean;
}

export default function SpotifyLogin({ login, force }: ISpotifyLoginProps) {
  const response_type = encodeURIComponent("code");
  const scope = encodeURIComponent(
    "user-read-email,app-remote-control,user-read-playback-state,user-modify-playback-state,user-read-currently-playing,streaming,user-read-private,playlist-read-collaborative,playlist-modify-public,user-library-modify,user-library-read,user-read-playback-position"
  );
  const redirect_uri = encodeURIComponent(
    `${window.location.origin}/spotify-callback`
  );
  const state = "";

  const openSpotifyLogin = () => {
    if (force) {
      window.location.href = `https://accounts.spotify.com/authorize?response_type=${response_type}&scope=${scope}&client_id=${process.env.REACT_APP_SPOTIFY_CLIENT_ID}&state=${state}&redirect_uri=${redirect_uri}&show_dialog=true`;
    } else {
      window.location.href = `https://accounts.spotify.com/authorize?response_type=${response_type}&scope=${scope}&client_id=${process.env.REACT_APP_SPOTIFY_CLIENT_ID}&state=${state}&redirect_uri=${redirect_uri}`;
    }
  };

  return (
    <Button
      className="btn-white"
      onClick={() => {
        openSpotifyLogin();
      }}
      data-testid="spotify-login-button"
    >
      {login ? "Log in to Spotify" : "Connect your spotify account"}
    </Button>
  );
}
