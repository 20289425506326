import axios from "axios";

const accessTokenState = async () => {
  const token = await localStorage.getItem("auth_token");
  return token;
};
const spotifyToken = async () => {
  const token = await localStorage.getItem("sp-accessToken");
  return token;
};

const baseUrl = () => {
  if (
    process.env.REACT_APP_NODE_ENV === "dev" ||
    process.env.REACT_APP_NODE_ENV === "test"
  ) {
    return "https://api.unhurd.co.uk/curator/test/";
  } else {
    return "https://api.unhurd.co.uk/curator/v1/";
  }
};

const spotifyUrl = "https://api.spotify.com/v1";

const authUrl = "https://test-unhurd.uk.auth0.com/api/v2/users/";

const DataService = {
  get: async function (url: string) {
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${await accessTokenState()}`,
      },
    });
  },
  post: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "POST",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await accessTokenState()}`,
      },
    });
  },
  put: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "PUT",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await accessTokenState()}`,
      },
    });
  },
  patchAuth: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: authUrl + url,
      method: "PATCH",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await accessTokenState()}`,
      },
    });
  },
  delete: async function (url: string, data: object) {
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${await accessTokenState()}`,
      },
    });
  },
  getSpotify: async function (url: string) {
    console.log(url);
    return axios({
      url: spotifyUrl + url,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${await spotifyToken()}`,
      },
    });
  },
  putSpotify: async function (url: string, data: object) {
    console.log(url);
    return axios({
      url: spotifyUrl + url,
      method: "PUT",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${await spotifyToken()}`,
      },
    });
  },
  postSpotify: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: spotifyUrl + url,
      method: "POST",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await spotifyToken()}`,
      },
    });
  },
  postSeedPitches: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: "https://api.unhurd.co.uk/seed/curator/" + url,
      method: "POST",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "unhurd-api-key": process.env.REACT_APP_PITCHES_SEED_API_KEY,
      },
    });
  },
};

export default DataService;
