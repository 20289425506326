import { AxiosError } from "axios";
import { SpotifyUserCheck, User } from "../models/Interfaces/UserInterfaces";
import DataService from "./DataService";

const unhurdId = async () => {
  const token = await localStorage.getItem("unhurdID");
  return token;
};

const userId = async () => {
  const token = await localStorage.getItem("userID");
  return token;
};

const UserService = {
  getUserByID: async function (id: string | undefined) {
    const url = `account/${id}`;
    return new Promise<User>((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          res(resp.data);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          if (err.response?.status === 404) {
            console.log("NO USER IN OUR DATABASE");
          }
          reject(err);
        });
    });
  },
  checkSpotifyAccountExists: async function (id: string) {
    const url = `account/spotify/${id}/validate`;
    return new Promise<SpotifyUserCheck>((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  createUser: async function (
    id: string | undefined,
    email: string | undefined,
    firstName: string,
    lastName: string,
    isGoogle?: boolean
  ) {
    const url = `account/`;
    const data = {
      userId: id,
      authenticationType: isGoogle ? 1 : 0,
      email: email,
      firstName: firstName,
      lastName: lastName,
    };
    return new Promise<User>((res, reject) => {
      DataService.post(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateUserOnboarding: async function (data: any) {
    const url = `account/${await unhurdId()}/${await userId()}/onboarding/`;
    return new Promise<User>((res, reject) => {
      DataService.put(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateUser: async function (data: any) {
    const url = `account/${await unhurdId()}/${await userId()}`;
    return new Promise<User>((res, reject) => {
      DataService.put(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updatePaypalDetails: async function (data: any) {
    const url = `account/${await unhurdId()}/${await userId()}/payPal`;
    return new Promise((res, reject) => {
      DataService.put(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  resendEmailVerification: async function (id: string) {
    const url = `account/${id}/verification-email`;
    return new Promise((res, reject) => {
      DataService.post(url, {})
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  deleteUser: async function () {
    const url = `account/${await unhurdId()}/${await userId()}`;
    return new Promise((res, reject) => {
      DataService.delete(url, {})
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default UserService;
