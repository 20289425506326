import DataService from "./DataService";

const PlaylistService = {
  getUserPlaylists: async function (id: string) {
    const url = `playlist/onboarding/${id}?includeConnected=true`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getPlaylist: async function (id: string, spotifyId: string | undefined) {
    const url = `playlist/${id}/${spotifyId}`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getConnectedUserPlaylists: async function (
    id: string | undefined,
    pageSize: number,
    status: string,
    sortBy: string,
    orderBy?: string,
    pageNumber?: number | ""
  ) {
    const url = `playlist/${id}/connected?pageSize=${pageSize}&status=${status}&sortBy=${sortBy}&orderBy=${orderBy}&pageNumber=${pageNumber}`;

    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  addPlaylists: async function (data: object) {
    const url = `playlist/`;
    return new Promise((res, reject) => {
      DataService.post(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getTrackForPlaylistPlacement: async function (id: string) {
    const url = `playlist/${id}/serve-track`;
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  verifyTrackPlaylistPlacement: async function (
    playlistId: string,
    trackId: string,
    servedAt?: string
  ) {
    let url = `playlist/${playlistId}/${trackId}/placed-track`;
    if (servedAt) {
      url = `playlist/${playlistId}/${trackId}/placed-track?trackServed=${servedAt}`;
    }
    return new Promise((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default PlaylistService;
