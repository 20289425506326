import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { dateTimeFormatter } from "../../formatters/DateTimeFormatter";

export interface ILineChartProps {
  data: any[];
}

export default function LineChartComponent({ data }: ILineChartProps) {
  // const data = [
  //   { name: "01/01/23", pending: 400, accepted: 300, rejected: 100 },
  //   { name: "02/01/23", pending: 500, accepted: 800, rejected: 200 },
  //   { name: "03/01/23", pending: 600, accepted: 400, rejected: 300 },
  //   { name: "04/01/23", pending: 700, accepted: 200, rejected: 200 },
  //   { name: "05/01/23", pending: 400, accepted: 100, rejected: 50 },
  //   { name: "06/01/23", pending: 500, accepted: 700, rejected: 200 },
  //   { name: "07/01/23", pending: 600, accepted: 400, rejected: 100 },
  //   { name: "08/01/23", pending: 700, accepted: 500, rejected: 400 },
  //   { name: "09/01/23", pending: 400, accepted: 300, rejected: 500 },
  //   { name: "10/01/23", pending: 500, accepted: 800, rejected: 100 },
  //   { name: "11/01/23", pending: 600, accepted: 400, rejected: 200 },
  //   { name: "12/01/23", pending: 700, accepted: 200, rejected: 300 },
  //   { name: "13/01/23", pending: 400, accepted: 100, rejected: 100 },
  //   { name: "14/01/23", pending: 500, accepted: 700, rejected: 600 },
  //   { name: "15/01/23", pending: 600, accepted: 400, rejected: 200 },
  //   { name: "16/01/23", pending: 700, accepted: 500, rejected: 100 },
  // ];

  const handleTooltip = (e: any) => {
    return (
      <div className="text-center">
        <p className="small">
          {e.payload[0]?.name}: {e.payload[0]?.value}
        </p>
        <p className="small">
          {e.payload[1]?.name}: {e.payload[1]?.value}
        </p>
      </div>
    );
  };

  return (
    <>
      <div data-testid="dashboard-line-chart">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart className="line-chart" data={data}>
            <defs>
              <linearGradient id="blue-gradient-1" x1="0" y1="0" x2="1" y2="0">
                <stop offset="5%" stopColor="#406bff" stopOpacity={1} />
                <stop offset="95%" stopColor="#05befe" stopOpacity={1} />
              </linearGradient>
              <linearGradient
                id="yellow-gradient-1"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
              >
                <stop offset="0%" stopColor="#ffae18" stopOpacity={1} />
                <stop offset="100%" stopColor="#fdd07a" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="pink-gradient-1" x1="0" y1="0" x2="1" y2="0">
                <stop offset="5%" stopColor="#f8005e" stopOpacity={1} />
                <stop offset="95%" stopColor="#f8629b" stopOpacity={1} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke="#383838" vertical={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(a: any, b: any) => {
                console.log(a, b);
                return dateTimeFormatter(a, "date");
              }}
            />
            <YAxis dataKey="total" allowDecimals={false} />
            <Line
              type="monotone"
              dot={false}
              name="Accepted"
              strokeWidth={2}
              dataKey="accepted"
              stroke="url(#blue-gradient-1)"
            />

            <Line
              type="monotone"
              dot={false}
              name="Rejected"
              dataKey="rejected"
              stroke="url(#pink-gradient-1)"
              strokeWidth={2}
            />
            <Tooltip content={handleTooltip} />
            <Legend verticalAlign="bottom" height={40} iconType="square" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
