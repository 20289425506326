import React, {
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppContext from "../Context/AppContext";
import Icon from "@mui/material/Icon";

export default function SnackbarComponent() {
  const [open, setOpen] = useState(false);
  const { snackbar } = useContext<any>(AppContext);
  const { dispatchSnackbar } = useContext<any>(AppContext);

  useEffect(() => {
    console.log(snackbar);
    setOpen(snackbar.open);
  }, [snackbar]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    console.log(reason);
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatchSnackbar({ type: "CLOSE_SNACKBAR" });
  };

  return (
    <>
      <Snackbar
        className={`snackbar-color-${snackbar.type}`}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={
          <div className="d-flex">
            {snackbar.type === "success" ? (
              <Icon>task_alt</Icon>
            ) : snackbar.type === "error" ? (
              <Icon className="material-symbols-outlined">error</Icon>
            ) : (
              <Icon className="material-symbols-outlined">info</Icon>
            )}
            <span className="pl16 pt2"> {snackbar.message}</span>
          </div>
        }
        action={
          <Fragment>
            <IconButton
              className="icon-btn no-bg"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      ></Snackbar>
    </>
  );
}
