import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  Icon,
} from "@mui/material";
import PitchesTable from "./PitchesTable";
import SpotifyService from "../services/SpotifyService";
import {
  SpotifyTracks,
  TrackPlayingIndex,
  UnhurdPlaylist,
} from "../models/Interfaces/PlaylistInterface";
import PlaylistService from "../services/PlaylistService";
import SpotifyLogin from "./SpotifyLogin";
import Equalizer from "./Equalizer";
import PitchesService from "../services/PitchesService";
import { pitchTrack } from "../models/Interfaces/TrackInterfaces";
import Loading from "./Loading";
import AppContext from "../Context/AppContext";

interface IPlaylistProps {
  playlist: UnhurdPlaylist;
  expanded?: boolean;
  playing?: any;
  playingIndex?: TrackPlayingIndex;
  setMultipleSelectedTracks: (
    tracks: pitchTrack[],
    playlist: UnhurdPlaylist
  ) => void;
}

export default function PlaylistAccordion({
  playlist,
  expanded,
  playing,
  playingIndex,
  setMultipleSelectedTracks,
}: IPlaylistProps) {
  document.title = "Playlist Pitches";
  const [open, setOpen] = useState(false);
  const [tracks, setTracks] = useState<pitchTrack[]>([]);
  const [isTracksLoading, setIsTracksLoading] = useState<boolean>(true);
  const [isPlayingIndex, setIsPlayingIndex] = useState<TrackPlayingIndex>();
  const [selectedTracks, setSelectedTracks] = useState<pitchTrack[]>([]);
  const [playlistDetails, setPlaylistDetails] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [contTokens, setContTokens] = useState<any[]>([
    { pageNumber: 0, contToken: "" },
  ]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { dispatchSnackbar } = useContext<any>(AppContext);

  useEffect(() => {
    console.log(playlist);
    if (expanded) {
      // getPlaylistData();
      getPlaylistTracks();
    }
  }, [playlist, expanded]);

  useEffect(() => {
    console.log(selectedTracks);
    setMultipleSelectedTracks(selectedTracks, playlist);
  }, [selectedTracks]);

  useEffect(() => {
    if (selectedTracks.length > 0) {
      setMultipleSelectedTracks(selectedTracks, playlist);
    }
  }, [selectedTracks]);

  useEffect(() => {
    setIsPlayingIndex(playingIndex);
  }, [playingIndex]);

  useEffect(() => {
    getPlaylistTracks();
  }, [pageNumber]);

  const getPlaylistTracks = () => {
    PitchesService.getPlaylistPitches(
      playlist.platform.id,
      10,
      "pending",
      contTokens[pageNumber].contToken
    )
      .then((resp: any) => {
        console.log(resp);
        const total =
          resp.total % 10 === 0
            ? Math.floor(resp.total / 10)
            : Math.floor(resp.total / 10) + 1;
        setTotalPages(total);
        console.log(total);
        console.log(contTokens.length);
        if (contTokens.length < total)
          setContTokens([
            ...contTokens,
            { pageNumber: pageNumber + 1, contToken: resp.continuationToken },
          ]);
        setTracks(resp?.items);
        setIsTracksLoading(false);
        document.title = resp.name;
      })
      .catch((err: any) => {
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: "Oops, something went wrong, try refreshing the page",
            type: "error",
          },
        });
      });
  };

  const getPlaylistData = () => {
    SpotifyService.getPlaylist(playlist.platform?.id)
      .then((resp: any) => {
        console.log(resp);
        if (playlistDetails !== resp) {
          setPlaylistDetails(resp);
        }
      })
      .catch((err) => {
        console.log(err);
        // if (!playlistDetails) {
        //   setOpen(true);
        // }
      });
  };

  const playTrackOnSpotify = (
    track: pitchTrack,
    index: string | undefined,
    pause: boolean | undefined,
    playlistId: string | undefined
  ) => {
    playing(track, index, pause, playlistId);
  };

  const toggleAccordion = (e: any, open: boolean) => {
    if (open) {
      if (!playlistDetails) {
        getPlaylistTracks();
      }
    }
  };

  const handleClose = (action: boolean) => {
    if (action) {
      console.log("logging in to spotify");
    }
    setOpen(false);
  };

  return (
    <div
      className={
        tracks.length > 0 && selectedTracks.length === tracks.length
          ? "to-do-card-border mb16"
          : "playlist-card mb16"
      }
    >
      {/* <Dialog open={open} onClose={handleClose}>
        <h4>
          It looks like you're not logged in to Spotify, would you like to log
          in?
        </h4>
        <div className="d-flex jc-center mt16">
          <Button
            className="border-btn"
            onClick={() => {
              handleClose(false);
            }}
            data-testid="deny-spotify-login"
          >
            No thanks
          </Button>{" "}
          <SpotifyLogin login={true} />
        </div>
      </Dialog> */}
      <Accordion
        className={
          tracks.length > 0 && selectedTracks.length === tracks.length
            ? "to-do-card"
            : "p1"
        }
        defaultExpanded={expanded}
        onChange={toggleAccordion}
      >
        <AccordionSummary
          expandIcon={
            <Icon
              className="accordion-expand-icon"
              data-testid="expand-accordion-button"
            >
              expand_more
            </Icon>
          }
        >
          <div className="min-h150 d-flex w100p">
            <img
              className="playlist-image"
              src={
                playlist?.images?.length > 0
                  ? playlist?.images[0]?.url
                  : "/images/logos/no-image-available.svg"
              }
              alt={""}
            />
            <div className="pl16 mt-auto mb-auto">
              <p className="text-faded mb16">
                {playlist.stats?.pendingPitches} pending pitches
              </p>
              <h2 className="mb16">{playlist.name}</h2>
              <div className="d-flex text-faded">
                {/* <p>
                  Followers:{" "}
                  <span className="text-faded">
                    {playlistDetails?.followers.total ||
                      playlist.followers ||
                      0}
                  </span>
                </p> */}
                <p>
                  Song Count:{" "}
                  <span className="text-faded">
                    {playlistDetails?.tracks.total || playlist.trackCount}
                  </span>
                </p>
              </div>
            </div>
            <div className="ml-auto mt-auto mb-auto">
              {isPlayingIndex?.playlistId === playlist.platform?.id ? (
                <Equalizer />
              ) : (
                ""
              )}
            </div>
          </div>
        </AccordionSummary>
        {!isTracksLoading && tracks.length > 0 ? (
          <AccordionDetails>
            <PitchesTable
              tracks={tracks}
              pageNumber={pageNumber}
              playingIndex={isPlayingIndex}
              playlistId={playlist.platform?.id}
              playing={(
                track: pitchTrack,
                index: string | undefined,
                pause: boolean | undefined,
                playlistId: string | undefined
              ) => {
                playTrackOnSpotify(track, index, pause, playlistId);
              }}
              selectedTracks={selectedTracks}
              setSelectedTracks={setSelectedTracks}
              updateTracks={() => {
                console.log("get refreshed tracks");
                setIsTracksLoading(true);
                setPageNumber(0);
                getPlaylistTracks();
                // getPlaylistData();
              }}
              bottomTrackIntersecting={() => {}}
            />
            <div className="d-flex mt16">
              <Button
                disabled={pageNumber === 0}
                className="ml-auto icon-btn"
                onClick={() => {
                  setIsTracksLoading(true);
                  setPageNumber(pageNumber - 1);
                }}
              >
                <Icon>chevron_left</Icon>
              </Button>

              <Button
                disabled={pageNumber === totalPages - 1}
                className="icon-btn"
                onClick={() => {
                  setIsTracksLoading(true);
                  setPageNumber(pageNumber + 1);
                }}
              >
                <Icon>chevron_right</Icon>
              </Button>
            </div>
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            {!isTracksLoading && (
              <div className="text-center mt16">
                <h4 data-testid="no-pitches-text">No Pitches available</h4>
              </div>
            )}
            {isTracksLoading && (
              <div className="centered-loading mt48">
                <Loading size="small" />
              </div>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
