import React from "react";

export default function Equalizer({ size }: { size?: string }) {
  return (
    <>
      <div className="soundwave-container" data-testid="equalizer-container">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </>
  );
}
