import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, Icon, Modal } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../models/Interfaces/UserInterfaces";
import SpotifyService from "../services/SpotifyService";
import {
  SpotifyPlaylist,
  SpotifyTracks,
  TrackPlayingIndex,
} from "../models/Interfaces/PlaylistInterface";
import SpotifyWebPlayer from "../components/SpotifyWebPlayer";
import AppContext from "../Context/AppContext";
import SpotifyLogin from "../components/SpotifyLogin";
import PitchesTable from "../components/PitchesTable";
import FeedbackModal from "../components/FeedbackModal";
import PlaylistService from "../services/PlaylistService";
import PitchesService from "../services/PitchesService";
import { pitchTrack } from "../models/Interfaces/TrackInterfaces";
import Loading from "../components/Loading";
import moment from "moment";

export default function PlaylistPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const { slug } = useParams<string>();
  const { dispatchSnackbar } = useContext<any>(AppContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [playlist, setPlaylist] = useState<any>();
  const [selectedTracks, setSelectedTracks] = useState<pitchTrack[]>([]);
  const [tracks, setTracks] = useState<pitchTrack[]>([]);
  const [totalTracks, setTotalTracks] = useState<number>(0);
  const [spDeviceId, setSpDeviceId] = useState<string>();
  const [currentTrack, setCurrentTrack] = useState<any>();
  const [currentState, setCurrentState] = useState<any>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isPlayingIndex, setIsPlayingIndex] = useState<TrackPlayingIndex>();
  const [accept, setAccept] = useState<boolean>();
  const [contToken, setContToken] = useState<string>("");
  const [trackLoading, setTracksLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const spAccessToken = localStorage.getItem("sp-accessToken");
  const spExpire = localStorage.getItem("sp-expireTime");

  useEffect(() => {
    return () => {
      if (currentTrack) {
        playTrackOnSpotify(currentTrack, undefined, true, playlist?.id);
      }
    };
  }, []);

  useEffect(() => {}, [modalOpen]);

  useEffect(() => {
    if (currentState === "TRACK_ENDED") {
      setIsPlayingIndex({ playlistId: undefined, trackIndex: undefined });
      setCurrentTrack(undefined);
    }
  }, [currentState]);

  const selectAll = () => {
    setSelectedTracks(tracks);
  };

  const isLoggedIn = () => {
    let isNotExpired = false;
    if (spExpire) {
      isNotExpired = moment(spExpire).isAfter(moment());
    }
    if (spAccessToken && isNotExpired) {
      return true;
    } else {
      return false;
    }
  };

  const playTrackOnSpotify = (
    track: pitchTrack,
    index: string | undefined,
    pause: boolean | undefined,
    playlistId: string | undefined
  ) => {
    const data = {
      uris: [`spotify:track:${track?.track?.spotifyId}`],
    };
    if (spDeviceId) {
      SpotifyService.playTrackOnSpotify(
        data,
        spDeviceId ? spDeviceId : "",
        pause
      )
        .then((resp: any) => {
          if (pause) {
            setIsPlayingIndex({
              playlistId: undefined,
              trackIndex: undefined,
            });
          } else {
            setIsPlayingIndex({ playlistId: playlistId, trackIndex: index });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.status === 502) {
          } else {
            setOpen(true);
          }
        });
    } else {
      console.log("no deviceid");
      SpotifyService.playTrackOnSpotify(data, "", pause)
        .then((resp: any) => {
          if (pause) {
            setIsPlayingIndex({
              playlistId: undefined,
              trackIndex: undefined,
            });
          } else {
            setIsPlayingIndex({ playlistId: playlist?.id, trackIndex: index });
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("open spotify and play something");
          if (err?.status === 401) {
            dispatchSnackbar({
              type: "OPEN_SNACKBAR",
              payload: {
                message:
                  "You need to be logged in to Spotify to use this feature",
                type: "",
              },
            });
          }
          if (
            (err?.reason === "PREMIUM_REQUIRED" || err?.status === 403) &&
            !pause
          ) {
            dispatchSnackbar({
              type: "OPEN_SNACKBAR",
              payload: {
                message:
                  "You need a Spotify Premium account to use this feature",
                type: "",
              },
            });
          }
          if (err.response?.status === 403) {
            dispatchSnackbar({
              type: "OPEN_SNACKBAR",
              payload: {
                message:
                  "It looks like this spotify account isn't registered with us - please send us a support message to resolve this issue.",
                type: "",
              },
            });
          }
        });
    }
  };

  const getPlaylistPitches = (id: string, reset?: boolean) => {
    console.log(currentState);
    console.log(currentTrack);
    PlaylistService.getPlaylist(id, unhurdUser?.spotifyAccounts[0].id).then(
      (resp: any) => {
        console.log(resp);
        setPlaylist(resp);
        document.title = resp?.name;

        PitchesService.getPlaylistPitches(
          resp?.platform.id,
          20,
          "pending",
          contToken || ""
        ).then((resp: any) => {
          console.log(resp);
          setContToken(resp?.continuationToken);
          setTotalTracks(resp.total);
          if (reset) {
            setTracks(resp?.items);
          } else {
            setTracks(tracks.concat(resp?.items));
          }
          if (currentTrack && currentState && !currentState.paused) {
            console.log(playlist.id);
            console.log(currentTrack);
            console.log(currentState.paused);
            setIsPlayingIndex({
              playlistId: playlist?.id,
              trackIndex: currentTrack.id,
            });
          }
          setTracksLoading(false);
          setIsLoadingMore(false);
        });
        if (!playlist) {
          SpotifyService.getPlaylist(resp?.platform.id)
            .then((resp: any) => {
              console.log(resp);
            })
            .catch((err) => {
              console.log(err);
              if (err.response?.data?.error?.status !== 502) {
                if (err.response.data.statusCode === 404) {
                  dispatchSnackbar({
                    type: "OPEN_SNACKBAR",
                    payload: {
                      message: "Couldn't find this playlist in our system",
                      type: "",
                    },
                  });
                }
                if (err.response?.status === 401) {
                  setOpen(true);
                }
              }
            });
        }
      }
    );
  };

  useEffect(() => {
    if (slug && unhurdUser) {
      // isLoggedIn();
      console.log(slug);
      console.log(unhurdUser);
      getPlaylistPitches(slug);
    }
  }, [slug, unhurdUser]);

  const handleClose = (action: boolean) => {
    if (action) {
      console.log("logging in to spotify");
    }
    setOpen(false);
  };

  const handleModalClose = (event: any) => {
    setModalOpen(false);
  };

  const playFromModal = (track: pitchTrack, pause: boolean | undefined) => {
    playTrackOnSpotify(track, undefined, pause, playlist.id);
  };

  const canPage = (entries: any) => {
    return (
      entries[0].isIntersecting &&
      !isLoadingMore &&
      entries[0].target.rowIndex < totalTracks
    );
  };

  return (
    <div className="page-content">
      <SpotifyWebPlayer
        deviceId={setSpDeviceId}
        currentTrack={setCurrentTrack}
        currentState={setCurrentState}
      />
      <Dialog open={open} onClose={handleClose}>
        <h4 data-testid="not-logged-into-spotify">
          It looks like you're not logged in to Spotify, would you like to log
          in?
        </h4>
        <div className="d-flex jc-center mt16">
          <Button
            className="border-btn"
            onClick={() => {
              handleClose(false);
            }}
            data-testid="refuse-spotify-login"
          >
            No thanks
          </Button>{" "}
          <SpotifyLogin login={true} />
        </div>
      </Dialog>
      <p
        className="text-faded cursor-pointer d-flex"
        onClick={() => {
          navigate("/playlists");
        }}
      >
        <Icon>chevron_left</Icon>
        <span className="pt2">Playlist pitches</span>
      </p>
      <div className="d-flex mt16">
        <img
          className="playlist-image"
          src={
            playlist?.images[0]?.url
              ? playlist?.images[0].url
              : "/images/logos/no-image-available.svg"
          }
          alt=""
        ></img>
        <div className="pl16">
          <h2 data-testid="playlist-title">{playlist?.name}</h2>
          <div className="d-flex mt16" data-testid="playlist-followers">
            <p>
              Followers:{" "}
              <span className="text-faded">{playlist?.followers}</span>
            </p>
            <p className="pl16" data-testid="playlist-song-count">
              Song Count:{" "}
              <span className="text-faded">{playlist?.trackCount}</span>
            </p>
          </div>

          <p className="text-faded mt16">
            You can review all of your pending pitches below and decide whether
            you want to either accept or reject them onto your playlist.
          </p>
        </div>
        {!isLoggedIn() && (
          <div className="ml-auto">
            <SpotifyLogin login />
          </div>
        )}
      </div>
      <div className="mt16 d-flex">
        <h3 className="mt-auto mb-auto" data-testid="playlist-pending-pitches">
          Pending pitches: {totalTracks}
        </h3>
        {tracks?.length > 0 && (
          <div className="ml-auto d-flex">
            {selectedTracks?.length !== tracks?.length && (
              <Button onClick={selectAll} data-testid="select-all-button">
                Select all
              </Button>
            )}
            {selectedTracks?.length === tracks?.length && (
              <Button
                onClick={() => {
                  setSelectedTracks([]);
                }}
                data-testid="deselect-all-button"
              >
                Deselect all
              </Button>
            )}
          </div>
        )}
      </div>
      {!trackLoading && tracks?.length > 0 && (
        <div
          className={
            selectedTracks.length === tracks?.length
              ? "to-do-card-border mt16"
              : "playlist-card mt16"
          }
        >
          <div
            className={
              selectedTracks.length === tracks.length ? "to-do-card" : "p1"
            }
          >
            <PitchesTable
              tracks={tracks}
              playlistId={playlist?.id}
              playingIndex={isPlayingIndex}
              playing={(
                track: pitchTrack,
                index: string | undefined,
                pause: boolean | undefined,
                playlistId: string | undefined
              ) => {
                playTrackOnSpotify(track, index, pause, playlistId);
              }}
              selectedTracks={selectedTracks}
              setSelectedTracks={setSelectedTracks}
              updateTracks={(update: boolean) => {
                if (update && slug) {
                  setIsPlayingIndex({
                    playlistId: undefined,
                    trackIndex: undefined,
                  });
                  setTracksLoading(true);
                  setSelectedTracks([]);
                  getPlaylistPitches(slug, true);
                }
              }}
              bottomTrackIntersecting={(entries: any) => {
                if (canPage(entries)) {
                  if (slug) {
                    setIsLoadingMore(true);
                    getPlaylistPitches(slug);
                  }
                }
              }}
            />
            {isLoadingMore && (
              <div className="text-center mt16">Loading more...</div>
            )}
          </div>
        </div>
      )}
      {!trackLoading && tracks?.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-pitches-available">No Pitches available</h4>
        </div>
      )}
      {trackLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}

      {selectedTracks.length > 0 && (
        <div className="playlist-select-footer d-flex">
          <div className="mt-auto mb-auto">
            {" "}
            <p>
              {selectedTracks.length}{" "}
              {selectedTracks.length === 1 ? "pitch" : "pitches"} selected
            </p>
          </div>
          <div className="ml-auto">
            <Button
              className="border-btn"
              onClick={() => {
                setAccept(false);
                setModalOpen(true);
              }}
            >
              <span className="btn-text icon-suffix">Reject</span>
              <Icon>close</Icon>
            </Button>
            <Button
              onClick={() => {
                setAccept(true);
                setModalOpen(true);
              }}
              className="btn-white"
            >
              <span className="btn-text icon-suffix">Accept</span>
              <Icon>done</Icon>
            </Button>
          </div>
        </div>
      )}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <React.Fragment>
          <FeedbackModal
            tracks={selectedTracks}
            accept={accept}
            closeModalOutput={setModalOpen}
            playTrack={playFromModal}
            updateTracks={() => {
              if (slug) {
                console.log("get playlists");
                setTracksLoading(true);
                setSelectedTracks([]);
                setIsPlayingIndex({
                  playlistId: undefined,
                  trackIndex: undefined,
                });
                getPlaylistPitches(slug, true);
              }
            }}
          ></FeedbackModal>
        </React.Fragment>
      </Modal>
    </div>
  );
}
