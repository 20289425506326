import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import { walletSetup } from "../models/UserModels";
import Loading from "../components/Loading";
import AppContext from "../Context/AppContext";

export default function PaypalCallbackPage({
  updateUser,
}: {
  updateUser: any;
}) {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const other: string | null = params.get("other");
  const code: string | null = params.get("code");
  const error_uri: string | null = params.get("error_uri");
  const { dispatchSnackbar } = useContext<any>(AppContext);

  useEffect(() => {
    if (code && other) {
      updatePayPal(code);
    }
    if (error_uri) {
      navigate("/");
    }
  }, [code, error_uri, other]);

  const updatePayPal = async (code: string) => {
    localStorage.setItem("pp-code", code);
    const data = {
      code: code,
    };
    await AuthService.getPayPalCredentials("auth/paypal/user-info", data).then(
      async (resp: any) => {
        if (other === "settings") {
          const wallet = {
            paypalDetails: {
              payerId: resp.payerId,
              email: resp.email,
              verified: resp.verified,
              name: resp.name,
              verifiedAccount: resp.verifiedAccount,
              emailVerified: resp.emailVerified,
              country: resp.country,
            },
          };
          UserService.updatePaypalDetails(wallet).then((resp: any) => {
            console.log(resp);
            dispatchSnackbar({
              type: "OPEN_SNACKBAR",
              payload: {
                message: "PayPal details updated",
                type: "success",
              },
            });
            updateUser(resp.userId);
            navigate("/settings", { state: 1 });
          });
        } else {
          const wallet = {
            payerId: resp.payerId,
            email: resp.email,
            verified: resp.verified,
            name: resp.name,
            verifiedAccount: resp.verifiedAccount,
            emailVerified: resp.emailVerified,
            country: resp.country,
          };
          const user = await walletSetup(wallet);
          UserService.updateUserOnboarding(user).then((resp: any) => {
            updateUser(resp.userId);
            navigate("/onboarding");
          });
        }
      }
    );
  };

  return (
    <>
      <div className="onboarding-page">
        <div className="centered-loading mt48">
          <Loading />
        </div>
      </div>
    </>
  );
}
