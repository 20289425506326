import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import PaypalLogin from "../components/PaypalLogin";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserHeader from "../components/UserHeader";
import { formReducer } from "../reducers/formReducer";
import UserService from "../services/UserService";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { User } from "../models/Interfaces/UserInterfaces";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import AppContext from "../Context/AppContext";
import AuthService from "../services/AuthService";
import PitchesService from "../services/PitchesService";
import { Button, CircularProgress } from "@mui/material";
import Loading from "../components/Loading";

export default function SettingsPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  document.title = "Settings";
  const location = useLocation();
  const [value, setValue] = useState(location.state || 0);
  const [open, setOpen] = useState(false);
  const [showSeed, setShowSeed] = useState(false);
  const [isCreatingPitches, setIsCreatingPitches] = useState<boolean>(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState<boolean>(false);

  const { logout } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const { dispatchSnackbar } = useContext<any>(AppContext);

  let initialFormState = {
    firstname: unhurdUser?.firstName || "",
    lastname: unhurdUser?.lastName || "",
    email: unhurdUser?.email || "",
    businessName: unhurdUser?.businessName || "",
  };
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  useEffect(() => {}, [location]);

  useEffect(() => {
    console.log(unhurdUser);
    if (unhurdUser) {
      formState.firstname = unhurdUser?.firstName;
      formState.lastname = unhurdUser?.lastName;
      formState.email = unhurdUser?.email;
      formState.businessName = unhurdUser?.businessName || "";
      setIsLoading(false);
    }
    if (process.env.REACT_APP_NODE_ENV === "dev") {
      console.log(process.env.REACT_APP_NODE_ENV);
      setShowSeed(true);
    }
    console.log(formState);
  }, [unhurdUser]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleFormChanges = () => {
    setIsSavingChanges(true);
    console.log(formState);
    UserService.updateUser(formState).then((resp: any) => {
      console.log(resp);
      formState.firstname = resp?.firstName;
      formState.lastname = resp?.lastName;
      formState.email = resp?.email;
      formState.businessName = resp?.businessName;
      dispatchSnackbar({
        type: "OPEN_SNACKBAR",
        payload: {
          message: "You've successfully updated your details",
          type: "success",
        },
      });
      setIsSavingChanges(false);
    });
  };

  const deleteAccount = () => {
    setOpen(true);
  };

  const handlePasswordChanges = () => {
    setIsChangingPassword(true);
    console.log(unhurdUser?.email);
    const data = {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: (formState.email = unhurdUser?.email),
      connection: "Username-Password-Authentication",
    };
    let url;
    if (process.env.REACT_APP_NODE_ENV === "dev") {
      url = "https://test-unhurd.uk.auth0.com/dbconnections/change_password";
    } else {
      url = "https://unhurd.eu.auth0.com/dbconnections/change_password";
    }
    axios({
      url: url,
      method: "POST",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        console.log(resp);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `${resp.data} This may take a couple of minutes to come through.`,
            type: "",
          },
        });
        setIsChangingPassword(false);
      })
      .catch((err) => {
        console.log(err);
        setIsChangingPassword(false);
      });
  };

  const formUpdate = (event: any) => {
    dispatch({
      type: "HANDLE INPUT TEXT",
      field: event.target.name,
      payload: event.target.value,
    });
    console.log(formState);
  };

  const handleClose = (e: any) => {
    console.log(e);
    if (e === true) {
      setIsDeletingAccount(true);
      UserService.deleteUser().then((resp) => {
        console.log(resp);
        AuthService.removeAllTokens();
        logout({ logoutParams: { returnTo: window.location.origin } });
      });
    }

    setOpen(false);
  };

  const createDemoPitches = () => {
    setIsCreatingPitches(true);
    console.log("seed pitches");
    PitchesService.seedPitches(unhurdUser?.spotifyAccounts[0].id)
      .then((resp: any) => {
        console.log(resp);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `New demo pitches have been added to your verified playlists`,
            type: "success",
          },
        });
        setIsCreatingPitches(false);
      })
      .catch((err: any) => {
        console.log(err);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `Oops, something went wrong. Try generating pitches again`,
            type: "error",
          },
        });
        setIsCreatingPitches(false);
      });
  };

  return (
    <>
      {!isDeletingAccount ? (
        <div className="page-content">
          <UserHeader unhurdUser={unhurdUser} />
          <div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Your details" data-testid="your-details" />
                  <Tab label="Payment details" data-testid="payment-details" />
                  <Tab
                    label="Security settings"
                    data-testid="security-settings"
                  />
                  {showSeed && <Tab label="Development Settings" />}
                </Tabs>
              </Box>
            </Box>
            {/* User form tab */}
            <div hidden={value !== 0}>
              <div className="user-info-form">
                {!isLoading && (
                  <form>
                    <div className="user-form-names">
                      <label>
                        <p>First name</p>
                        <input
                          placeholder="John"
                          name="firstname"
                          value={formState.firstname}
                          onChange={formUpdate}
                          data-testid="first-name-input"
                        ></input>
                      </label>
                      <label>
                        <p>Last name</p>
                        <input
                          placeholder="Smith"
                          name="lastname"
                          value={formState.lastname}
                          onChange={formUpdate}
                          data-testid="last-name-input"
                        ></input>
                      </label>
                    </div>
                    <label>
                      <p>Business name</p>
                      <input
                        placeholder="Business name (optional)"
                        name="businessName"
                        value={formState.businessName}
                        onChange={formUpdate}
                        data-testid="business-name-input"
                      ></input>
                    </label>
                    <label>
                      <p className="mt16">Email address</p>
                      <input
                        placeholder="Enter email address"
                        name="email"
                        disabled={true}
                        value={formState.email}
                        onChange={formUpdate}
                        data-testid="email-input"
                      ></input>
                    </label>
                  </form>
                )}
              </div>
              <Button
                disabled={isSavingChanges}
                className="btn-white btn-no-margins mt16"
                onClick={handleFormChanges}
                data-testid="save-changes-button"
              >
                {isSavingChanges ? (
                  <CircularProgress size={16} />
                ) : (
                  "Save changes"
                )}
              </Button>
            </div>
            {/* Payment details tab */}
            <div hidden={value !== 1}>
              {unhurdUser && !unhurdUser?.paymentDetails?.payPal?.email ? (
                <div className="text-center">
                  <img
                    className="mt48"
                    src="/images/paypal-logo.svg"
                    alt="email"
                  ></img>
                  <div></div>
                  <p className="text-faded max-w600 m-auto mt32">
                    You haven’t connected a PayPal account yet. In order to get
                    paid from your playlist pitches you need to connect and
                    verify your PayPal account.
                  </p>
                  <div className="mt32">
                    <PaypalLogin page="settings" />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="top-cards">
                    <div className="title-card max-w500">
                      <div className="card-title">
                        <img
                          className="card-icon"
                          src="/images/paypal-logo.svg"
                          alt="email"
                        ></img>
                        <div className="titles">
                          <div className="text-faded">PayPal account</div>
                          <div className="text-number">Connected</div>
                        </div>
                        <div className="ml-auto">
                          <PaypalLogin page="settings" type="change" />
                        </div>
                      </div>
                      <label>
                        <p className="mt16">Email address</p>
                        <input
                          placeholder="Enter email address"
                          name="email"
                          disabled={true}
                          value={unhurdUser?.paymentDetails?.payPal.email}
                          data-testid="paypal-email-input"
                        ></input>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Security settings tab */}
            <div hidden={value !== 2}>
              <h3>Security settings</h3>
              <Button
                disabled={isChangingPassword}
                onClick={handlePasswordChanges}
                data-testid="change-password-button"
              >
                {isChangingPassword ? (
                  <CircularProgress size={16} />
                ) : (
                  "Change password"
                )}
              </Button>
              <Button
                onClick={deleteAccount}
                data-testid="delete-account-button"
              >
                Delete Account
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <h4>Are you sure you want to delete your account?</h4>
                <div className="d-flex jc-center mt16">
                  <Button
                    className="border-btn"
                    onClick={() => {
                      handleClose(false);
                    }}
                    data-testid="deny-delete-account"
                  >
                    No
                  </Button>{" "}
                  <Button
                    className="btn-white"
                    onClick={() => {
                      handleClose(true);
                    }}
                    data-testid="confirm-delete-account"
                  >
                    Yes
                  </Button>
                </div>
              </Dialog>
            </div>
            {/* Development settings tab */}
            <div hidden={value !== 3}>
              <h3>Development settings</h3>
              <Button disabled={isCreatingPitches} onClick={createDemoPitches}>
                {!isCreatingPitches ? (
                  "Create demo pitches"
                ) : (
                  <CircularProgress size={16} />
                )}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </>
  );
}
