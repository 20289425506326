import React, { useEffect, useReducer, useState } from "react";
import VerifyEmail from "../components/onboarding/VerifyEmail";
import UserInfo from "../components/onboarding/UserInfo";
import SpotifyConnect from "../components/onboarding/SpotifyConnect";
import ChoosePlaylists from "../components/onboarding/ChoosePlaylists";
import WalletSetup from "../components/onboarding/WalletSetup";
import SettingUp from "../components/onboarding/SettingUp";
import ReadyToGo from "../components/onboarding/ReadyToGo";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { User } from "../models/Interfaces/UserInterfaces";
import { onboardingReducer } from "../reducers/onboardingReducer";
import OnboardingContext from "../Context/OnboardingContext";
import { Slider } from "@mui/material";
import ConnectSpotifyAccount from "../components/onboarding/ConnectSpotifyAccount";

export default function OnboardingPage({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  document.title = "Onboarding";
  const location = useLocation();

  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const initialState = {
    page: undefined,
  };

  useEffect(() => {
    console.log(location);
  }, [location]);

  const [state, dispatch] = useReducer(onboardingReducer, initialState);

  useEffect(() => {
    setIsLoading(true);

    UserService.getUserByID(user?.sub)
      .then((resp: any) => {
        console.log(resp);
        if (!user?.email_verified || resp === undefined) {
          dispatch({
            type: "SET PAGE",
            payload: { page: 0 },
          });
        } else if (
          resp.accountConfiguration === null ||
          resp.accountConfiguration.onboardingStage === 0
        ) {
          dispatch({
            type: "SET PAGE",
            payload: { page: 1 },
          });
        } else if (resp.accountConfiguration.onboardingStage === 4) {
          dispatch({
            type: "SET PAGE",
            payload: {
              page: resp.accountConfiguration.onboardingStage,
            },
          });
        } else {
          dispatch({
            type: "SET PAGE",
            payload: {
              page: resp.accountConfiguration.onboardingStage + 1,
            },
          });
        }
        if (unhurdUser) {
          if (unhurdUser.accountConfiguration.onboardingStage === 4) {
            dispatch({
              type: "SET PAGE",
              payload: {
                page: unhurdUser.accountConfiguration.onboardingStage,
              },
            });
          } else {
            dispatch({
              type: "SET PAGE",
              payload: {
                page: unhurdUser.accountConfiguration.onboardingStage + 1,
              },
            });
          }
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        dispatch({
          type: "SET PAGE",
          payload: { page: 0 },
        });
        setIsLoading(false);
      });
    if (unhurdUser && unhurdUser.accountConfiguration.onboardingStage === 5) {
      navigate("/dashboard");
    }
  }, [user, unhurdUser]);

  return (
    <OnboardingContext.Provider value={{ state, dispatch }}>
      {!isLoading ? (
        <div className="onboarding-page">
          {state.page > 0 && state.page < 5 && (
            <div className="max-w200 m-auto mt48">
              {" "}
              <p className="text-blue">
                Step {state.page} out of {4}
              </p>
              <Slider value={(100 / 4) * state.page}></Slider>
            </div>
          )}
          {state.page === 0 && <VerifyEmail />}
          {/* Tell us about yourself */}
          {state.page === 1 && location.state !== "goToSpotifyConnect" && (
            <UserInfo />
          )}
          {/* Connect Spotify Account */}
          {(state.page === 2 ||
            (state.page === 1 && location.state === "goToSpotifyConnect")) && (
            // <ConnectSpotifyAccount />
            <SpotifyConnect />
          )}
          {/* Choose Your Playlists */}
          {state.page === 3 && <ChoosePlaylists />}
          {/* Set Up Wallet */}
          {state.page === 4 && <WalletSetup unhurdUser={unhurdUser} />}
          {/* Setting you up */}
          {state.page === 5 && <SettingUp />}
          {/* Ready to go */}
          {state.page === 6 && <ReadyToGo />}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </OnboardingContext.Provider>
  );
}
