import { Button, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import PieChartComponent from "./charts/PieChart";
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";
import MetricsService from "../services/MetricsService";
import { User } from "../models/Interfaces/UserInterfaces";

export default function PlaylistStatsCard({
  unhurdUser,
}: {
  unhurdUser: User | undefined;
}) {
  const [totalPlaylists, setTotalPlaylists] = useState<number>(0);
  const [playlistPieData, setPieData] = useState<any>(undefined);
  const [userHasPlaylists, setUserHasPlaylists] = useState<boolean>(true);
  const [playlistData, setPlaylistData] = useState<any>();

  const navigate = useNavigate();

  const getPlaylistStats = () => {
    MetricsService.getConnectedPlaylistStats(unhurdUser?.id, unhurdUser?.userId)
      .then((resp: any) => {
        console.log(resp);
        setPlaylistData(resp);
        setTotalPlaylists(resp.connected);
        if (resp.connected === 0) {
          setUserHasPlaylists(false);
        }

        setPieData([
          {
            name: "Rejected",
            value: resp.rejected,
            fill: "url(#pink-gradient)",
          },
          {
            name: "Pending",
            value: resp.pending,
            fill: "url(#yellow-gradient)",
          },
          {
            name: "Verified",
            value: resp.accepted,
            fill: "url(#blue-gradient)",
          },
        ]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (unhurdUser) {
      getPlaylistStats();
    }
  }, [unhurdUser]);

  return (
    <>
      <div
        className="title-card playlists-connected-card cursor-pointer"
        onClick={() => {
          if (!userHasPlaylists) {
            navigate("/add-playlist");
          } else {
            navigate("/playlists");
          }
        }}
      >
        <div className="card-title">
          <div className="card-icon material-icons">
            <img src="/images/icons/PlaylistIcon.svg" alt=""></img>
          </div>

          <div className="titles">
            <div className="text-faded">Playlists connected</div>
            <div className="text-number">{totalPlaylists}</div>
          </div>
          <Button
            className="icon-btn ml-auto"
            onClick={() => {
              navigate("/playlists");
            }}
          >
            <Icon>chevron_right</Icon>
          </Button>
        </div>
        <div className="pos-rel pt16 pb16">
          <PieChartComponent
            total={totalPlaylists}
            playlistData={playlistPieData}
          />
          <p className="text-center text-faded mt16 mb16 max-w90p m-auto">
            You’ve connected{" "}
            <span className="text-white">{totalPlaylists}</span> playlists which
            is {totalPlaylists} more than last week
          </p>
          {playlistPieData && (
            <div className="mb32">
              <ProgressBar
                title="Verified"
                label={playlistPieData[2].value}
                progress={(100 / totalPlaylists) * playlistPieData[2].value}
              />
              <ProgressBar
                title="Pending"
                label={playlistPieData[1].value}
                progress={(100 / totalPlaylists) * playlistPieData[1].value}
                color="yellow"
              />
              <ProgressBar
                title="Rejected"
                label={playlistPieData[0].value}
                progress={(100 / totalPlaylists) * playlistPieData[0].value}
                color="pink"
              />
            </div>
          )}
          {!userHasPlaylists && (
            <div className="overlay-blur">
              <p className="text-faded mb16 mt100 pl16 pr16">
                It looks like you haven’t connected any of your playlists yet.
                Try to connect them now in order to receive pitches.
              </p>
              <Button
                onClick={() => {
                  navigate("/add-playlist");
                }}
                className="btn-white min-w180 m-auto"
              >
                <span className="btn-text icon-suffix">Add new playlist</span>
                <Icon>add</Icon>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
